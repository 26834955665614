import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Enums } from '../enums/enums';
import { LocaleService } from '../locale/locale.service';

@Component({
  selector: 'sd-leads-overview-billboard',
  templateUrl: './leadsOverviewBillboard.component.html'
})
export class LeadsOverviewBillboardComponent {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() subtitleOne: string;
  @Input() subtitleTwo: string;
  @Input() iconClass: string;
  @Input() trendArrow: number;
  @Input() showTrendArrow: number;
  @Input() metricCurrent: number;
  @Input() metricPreviousMonth: number;
  @Input() metricMOM: number;
  @Input() metricPreviousYear: number;
  @Input() metricYOY: number;
  @Input() metricFormatter: any;

  @Input() secondaryMetricCard: boolean;
  @Input() secondaryMetricLabel: string;
  @Input() secondaryMetric: string;
  @Input() secondaryMetricFormatter: string;
  @Input() footerOneLeftLabel: string;
  @Input() footerOneLeftValue: string;
  @Input() footerOneLeftMetricFormatter: string;
  @Input() footerOneRightLabel: string;
  @Input() footerOneRightValue: string;
  @Input() footerOneRightMetricFormatter: string;
  @Input() footerTwoMetricFormatter: string;
  @Input() footerTwoLeftLabel: string;
  @Input() footerTwoLeftValue: string;
  @Input() footerTwoRightLabel: string;
  @Input() footerTwoRightValue: string;
  @Input() billboardColorClass: string;
  @Input() reverseMetric: boolean;
  @Input() footerRowCount: number;
  @Input() locale: string;
  @Output() openHelpText = new EventEmitter();
  private enums = Enums;

  constructor() {}

  getTrendArrowIconClass(trend: number): string {
    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }

  getTrendColor(trend: number): string {
    // JMSTODO: I would prefer trend colors to be inputs, but that makes more lines elsewhere
    if (this.reverseMetric) {
      // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
      if (!trend) {
        return '';
      } else if (trend <= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    } else {
      if (!trend) {
        return '';
      } else if (trend >= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    }
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }

}
