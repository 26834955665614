<mat-card class="mat-elevation-z1" style="background-color: white; color: black;">
  <mat-card-title>
      <i class="fa pad-right" [ngClass]="iconClass"></i><b>{{title}}</b>
      <div class="pull-right ellipses-dropdown">
          <button mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
          <mat-menu #menu="matMenu">
              <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">{{'Help' | translate: locale}}</button>
          </mat-menu>
      </div>
  </mat-card-title>
  <mat-card-content class="card-block target-card">
      <table>
          <tbody>
              <tr>
                  <td>
                      <b style="font-size: 1.15em;">
                          {{metricCurrent | formatter : metricFormatter}}
                      </b>
                      <i *ngIf="showTrendArrow" [ngClass]="[trendArrow, getTrendColor(metricMOM)]" class="pad-left" style="font-size: 1em;"></i>
                  </td>
              </tr>
              <tr *ngIf="subtitle">
                  <td class="box-label">{{subtitle}}</td>
              </tr>
              <tr *ngIf="subtitleOne != null"> <!-- We need to use != null otherwise it doens't display 0s-->
                <td class="box-label" style="font-style: italic">{{'Prev. MTD' | translate: locale}}: {{subtitleOne | formatter : metricFormatter}}</td>
              </tr>
              <tr *ngIf="subtitleTwo != null"> <!-- We need to use != null otherwise it doens't display 0s-->
                  <td class="box-label" style="font-style: italic">{{'Prev. Month' | translate: locale}}: {{subtitleTwo | formatter : metricFormatter}}</td>
              </tr>
          </tbody>
      </table>
  </mat-card-content>
  <mat-card-footer *ngIf="footerOneLeftLabel">
    <table>
        <tbody>
            <tr>
                <td>
                    {{footerOneLeftLabel}}: <b>{{footerOneLeftValue | formatter : footerOneLeftMetricFormatter}}</b>
                </td>
                <td>
                    {{footerOneRightLabel}}:
                    <b [ngStyle]="{'color': 'green'}">
                        {{footerOneRightValue}}
                    </b>
                </td>
            </tr>
        </tbody>
    </table>
  </mat-card-footer>
  <mat-card-footer *ngIf="footerTwoLeftLabel">
    <table>
        <tbody>
            <ng-container *ngIf="secondaryMetricCard">
                <table>
                    <tbody>
                      <tr>
                        <td rowspan="2" style="text-align: left;">
                            <div *ngIf="!!secondaryMetricLabel">
                                {{secondaryMetricLabel}}: <b>{{ secondaryMetric | formatter: secondaryMetricFormatter}}</b>
                            </div>
                        </td>
                        <td rowspan="1" style="text-align: right;">
                            {{footerTwoLeftLabel}}:
                            <b [ngClass]="getTrendColor(metricMOM)">
                              {{metricMOM | formatter: footerTwoMetricFormatter}}
                            </b>

                        </td>
                      </tr>
                      <tr>
                        <td rowspan="1" style="text-align: right;">
                          {{footerTwoRightLabel}}:
                          <b [ngClass]="getTrendColor(metricYOY)">
                            {{metricYOY | formatter: footerTwoMetricFormatter}}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </ng-container>
            <ng-container *ngIf="!secondaryMetricCard">
                <tr>
                    <td>
                        <div *ngIf="metricMOM">
                            {{footerTwoLeftLabel}}: <b [ngClass]="getTrendColor(metricMOM)">
                                {{metricMOM | formatter : footerTwoMetricFormatter}}</b>
                        </div>
                        <div *ngIf="!metricMOM">
                            {{footerTwoLeftLabel}}: <b>N/A</b>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="metricYOY">
                            {{footerTwoRightLabel}}: <b [ngClass]="getTrendColor(metricYOY)">
                                {{metricYOY | formatter : footerTwoMetricFormatter}}</b>
                        </div>
                        <div *ngIf="!metricYOY">
                            {{footerTwoRightLabel}}: <b>N/A</b>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
  </mat-card-footer>
</mat-card>
