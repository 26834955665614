import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ContentModel, ListRequestModel, ListResponseModel } from '../../models/models-index';
import * as Models from '../../../_shared/models/models-index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() { }

  ngOnDestroy(): void { }

  private api = `${environment.baseApiUri}/content/`;

  getContent(name: string, locale?: string) {
    const localeSuffix = (!locale || locale === 'en') ? '' : '_' + locale;
    return this.http.get<ContentModel>(`${this.api}?name=${name + localeSuffix}`);
  }

  getList(requestModel: ListRequestModel) {
    return this.http.post<ListResponseModel<ContentModel>>(
      `${this.api}/list`,
      requestModel,
      { headers: this.headers });
  }

  processContent(content: ContentModel, file: File): Observable<any> {
    let request: Observable<any>;

    switch (content.type) {
      case 'File':
        let fileContentModel = <Models.FileContentModel>content;
        fileContentModel.file = file;
        request = this.upsertFileContent(fileContentModel);
        break;
      default: // Html Content
        let htmlContentModel = <Models.HtmlContentModel>content;
        htmlContentModel.html = <string>htmlContentModel.value;
        request = !htmlContentModel.id
          ? this.createContent(htmlContentModel)
          : this.updateContent(htmlContentModel);
        break;
    }

    return request;
  }

  createContent(content: ContentModel) {
    return this.http.post<ContentModel>(
      this.api,
      content,
      { headers: this.headers }
    );
  }

  updateContent(content: ContentModel) {
    return this.http.put<ContentModel>(
      this.api + content.id,
      content,
      { headers: this.headers }
    );
  }

  upsertFileContent(content: Models.FileContentModel) {
    let queryParams = `?name=${content.name}&filename=${content.file.name}&userId=${content.createdBy}&id=${content.id}&active=${content.isActive}`;

    return this.http.post(
      `${this.api}/file` + queryParams,
      content.file
    );
  }

  deleteContent(id: number) {
    return this.http.delete(this.api + id);
  }
}
