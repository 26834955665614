<div class="row" *ngIf="funnelRows">
  <div class="processrowheader sticky-header">
    <div class="box-label"></div>
    <div class="box" *ngFor="let header of headers">{{ header }}</div>
  </div>
</div>
<div class="funnel-row" *ngFor="let row of funnelRows">
  <div class="row">
    <div class="processgoalrow">
      <div class="box-label"></div>
      <div class="box" *ngFor="let segment of row.segments">{{ segment.descriptionValue }}</div>
    </div>
  </div>
  <div class="row">
    <div class="processrow">
      <div class="box-label">{{row.title || row.name }}</div>
      <ng-container *ngFor="let segment of getSegmentsForDisplay(row)">
        <div [ngClass]="segment.classes">{{segment.value}}</div>
        <svg *ngIf="segment.path" class="box1" style="width: 25px">
          <path [attr.d]="segment.path" [attr.class]="segment.pathClass" />
        </svg>
      </ng-container>
    </div>
  </div>
</div>
