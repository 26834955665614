import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as FilterPanelTypes from '../types/types';

@Injectable({
  providedIn: 'root',
  // useFactory: () => new SdFilterPanelValueService()
})
export class SdFilterPanelValueService {
  private _treeDataSource = new BehaviorSubject<FilterPanelTypes.IFilterTreeNode[]>([]);
  treeDataSource$: Observable<FilterPanelTypes.IFilterTreeNode[]> = this._treeDataSource.asObservable();

  private _selectedItems = new BehaviorSubject<FilterPanelTypes.FilterDataTypeValue[]>([]);
  selectedItems$: Observable<any[]> = this._selectedItems.asObservable();

  private _applyDisabled = new BehaviorSubject<boolean>(true);
  applyDisabled$: Observable<boolean> = this._applyDisabled.asObservable();

  constructor() { }

  compareValues(x: FilterPanelTypes.FilterDataTypeValue, y: FilterPanelTypes.FilterDataTypeValue) {
    return x.value === y.value;
  }

  addSelectedItem(item: any): void {
    const currentItems = this._selectedItems.getValue();
    currentItems.push(item);
    this._selectedItems.next(currentItems);
    this.setApplyDisabled();
  }

  clear() {
    this._selectedItems.next([]);
  }

  setSelectedItems(items: any[]): void {
    this._selectedItems.next(items);
    this.setApplyDisabled();
  }

  removeSelectedItem(item: any): void {
    let currentItems = this._selectedItems.getValue();
    currentItems = currentItems.filter(ci => ci !== item);

    this._selectedItems.next(currentItems);
    this.setApplyDisabled();
  }

  deselectAllSelectedItems(): void {
    this._selectedItems.next([]);
    this.setApplyDisabled();
  }

  deselectItems(items: any[]): void {
    let currentItems = this._selectedItems.getValue();
    if (currentItems) {
      items.forEach((node) => {
        currentItems = currentItems.filter(ci => ci.value !== node.value);
      });

      this._selectedItems.next(currentItems);
      this.setApplyDisabled();
    }
  }

  hasItemSelected(item: FilterPanelTypes.FilterDataTypeValue) {
    const selected = this._selectedItems.value.find(si => this.compareValues(si, item));
    return !!selected;
  }

  getValues(): any[] {
    const data = this._selectedItems.getValue();
    return data;
  }

  updateTreeDataSource(nodes: FilterPanelTypes.IFilterTreeNode[]): void {
    this._treeDataSource.next(nodes);
  }

  private setApplyDisabled() {
    this._applyDisabled.next(this._selectedItems.getValue().length <= 0);
  }
}
