import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable()
@Pipe({ name: 'formatnumber' })
export class FormatNumberPipe implements PipeTransform {
    transform(val: any, formatKey?: string): number {
        if (!formatKey) {
            if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
                return 0;
            } else {
                return Number(val)
            }
        } else if (formatKey === 'decimalToPercentTwoDecimals') {
            if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
                return 0;
            }
            const number = (Math.floor(val * 10000) / 10000) * 100;
            return number;
        } else if (formatKey === 'roundToHundredth') {
            if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
                return 0;
            }
            const number = Math.round(val * 100) / 100;
            return number;
        } else if (formatKey === 'roundToInteger') {
            if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
                return 0;
            }
            const number = Math.round(val);
            return number;
        } else if (formatKey === 'roundToTenth') {
            if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
                return 0;
            }
            const number = Math.round(val * 10) / 10;
            return number;
        } else {
            if (val === null || val === undefined || Number.isNaN(val) || val === Infinity || typeof val !== 'number') {
                return 0;
            }
            return Number(val);
        }
    }
}
