import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Enums, IEnums } from '../../../_shared/enums/enums';
import {
  AuthenticationService, ConfigurationService, LocaleService, SpinnerService
} from '../../../_shared/services/services-index';
import * as APIService from '../../../_shared/services/api/api-index';
import * as Models from '../../../_shared/models/models-index';
import { passwordMatch } from '../../../_shared/directives/passwordCompare.directive';
import { ReportsService } from '../../../_shared/services/api/api-index';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ManagementTranslationService } from '../../../_shared/locale/translation/management-translation.service';

@Component({
  selector: 'user-details-edit-modal',
  templateUrl: './userDetailsEdit.component.html'
})

export class UserDetailsEditComponent implements OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  isNew = false;
  roles: Models.Role[];
  orgLookups: Models.IOrgLookup[];
  dealerGroupLookups: Models.IOrgLookup[];
  orgLookupTypes: Models.OrgLookupType[];
  dealerLookups: Models.IDealerLookup[];
  orgRoles = this.configService.org.orgConfig.showUserBrand;
  showUserBrand = !!this.orgRoles?.length;
  locales = this.configService.org.orgConfig.availableLocales;
  localeAvailable = !!this.locales?.length;
  enums: IEnums = Enums;
  confirmPassword = '';
  currentPassword = '';
  errorMessage: string;
  password = '';
  userDetailsForm: FormGroup;
  userName: FormControl;
  firstName: FormControl;
  lastName: FormControl;
  isUserActive: FormControl;
  userRoles: FormControl;
  userRoleEntity: FormControl;
  userOrgLookupTypeIds: FormControl;
  userLocale: FormControl;
  userCurrentPassword: FormControl;
  userNewPassword: FormControl;
  userConfirmNewPassword: FormControl;
  labels: any;
  private authInfo: Models.IAuthenticationInfo;

  public get disableSave() {
    if (!this.userDetailsForm) {
      return false;
    }

    return !this.userDetailsForm.valid;
  }

  constructor(
    public dialogRef: MatDialogRef<UserDetailsEditComponent>,
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private userService: APIService.UsersService,
    private spinner: SpinnerService,
    private reportsService: ReportsService,
    private localeService: LocaleService,
    private translationService: ManagementTranslationService,
    public configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public user?: any) { }

  ngOnInit(): void {

    this.subscriptions.push(
      this.authService.loggedInUser$.subscribe(user => {
        this.authInfo = user;
      }),

      this.loadRoles().subscribe(),
      this.loadOrgLookupTypes().subscribe(),

      this.store.select(AppSelectors.selectConfigState).subscribe(config => {
        this.dealerLookups = config.dealerLookups;
        this.orgLookups = config.orgLookups;
        this.dealerGroupLookups = config.dealerGroupLookups;
      }),

      this.localeService.locale$.subscribe(loc => this.labels = { ...this.translationService.config.userManagement[loc] })
    );



    this.isNew = !this.user.userName;
    if (this.isNew) {
      this.user = <Models.User>{
        userName: null,
        firstName: null,
        lastName: null,
        role: null,
        roleEntity: null
      };
    }

    this.userName = new FormControl('', [Validators.required, Validators.email]);
    this.firstName = new FormControl('');
    this.lastName = new FormControl('');
    this.isUserActive = new FormControl('');
    this.userRoles = new FormControl('', Validators.required);
    this.userRoleEntity = new FormControl('', this.validateRoleEntity());
    this.userCurrentPassword = new FormControl('');
    this.userNewPassword = new FormControl('', Validators.minLength(8));
    this.userConfirmNewPassword = new FormControl('', [Validators.minLength(8), passwordMatch(this.userNewPassword)]);
    this.userOrgLookupTypeIds = new FormControl(null);
    this.userLocale = new FormControl(null);

    this.userDetailsForm = new FormGroup({
      userName: this.userName,
      firstName: this.firstName,
      lastName: this.lastName,
      isUserActive: this.isUserActive,
      userRoles: this.userRoles,
      userRoleEntity: this.userRoleEntity,
      userCurrentPassword: this.userCurrentPassword,
      userNewPassword: this.userNewPassword,
      userConfirmNewPassword: this.userConfirmNewPassword,
      userOrgLookupTypeIds: this.userOrgLookupTypeIds,
      userLocale: this.userLocale,
    });

    this.userDetailsForm.controls['userRoleEntity'].updateValueAndValidity();
    this.spinner.hide();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  compareRoles(dropDownRole: Models.Role, userRole: Models.Role): boolean {
    if (dropDownRole !== null && userRole !== null) {
      return dropDownRole.name === userRole.name;
    } else {
      return false;
    }
  }

  compareLocales(dropDownLocale: string, userLocale: string): boolean {
    if (dropDownLocale !== null && userLocale !== null) {
      return dropDownLocale === userLocale;
    } else {
      return false;
    }
  }

  compareOrgLookupTypes(dropDownOrgLookupType: Models.OrgLookupType, userOrgLookupType: Models.OrgLookupType): boolean {
    if (dropDownOrgLookupType !== null && userOrgLookupType !== null) {
      return dropDownOrgLookupType.orgLookupTypeId === userOrgLookupType.orgLookupTypeId;
    } else {
      return false;
    }
  }

  hideCurrentPassword(): boolean {
    if (this.user) {
      if (this.user.userId !== this.authInfo.userId) {
        return true;
      } else {
        return false;
      }
    } else if (this.isNew) {
      return true;
    }

    return false;
  }

  private loadRoles(): Observable<Models.Role[]> {
    this.spinner.show();
    return this.userService.getRoles()
      .pipe(
        tap(roleResponse => {
          this.roles = roleResponse;
          return roleResponse;
        })
      );
  }

  private loadOrgLookupTypes(): Observable<Models.OrgLookupType[]> {
    this.spinner.show();
    return this.userService.getOrgLookupTypes()
      .pipe(
        tap(orgLookupTypeResponse => {
          this.orgLookupTypes = orgLookupTypeResponse;
          return orgLookupTypeResponse;
        })
      );
  }

  // roleOrgRequired(): boolean {
  //   if (!this.user || !this.user.role || !this.showUserBrand) {
  //     return false;
  //   }

  //   return !!this.orgRoles.find(r => r.toLowerCase() === this.user.role.name.toLowerCase());
  // }

  roleUpdated(evt: any) {
  }

  roleEntityRequired(): boolean {
    if (!this.user || !this.user.role) {
      return false;
    }
    else {
      return this.configService.role.isOrg3RoleId(this.user.role.roleId) ||
        this.configService.role.isOrg2RoleId(this.user.role.roleId) ||
        this.configService.role.isOrg1RoleId(this.user.role.roleId) ||
        this.configService.role.isDealerRoleId(this.user.role.roleId) ||
        this.configService.role.isDealerGroupRoleId(this.user.role.roleId);
    }
  }

  // TODO: Build out this validation logic...right you can create an org1 user with a role entity that is a Dealer Group...edge case but we should fix it
  validateRoleEntity(): ValidationErrors {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!this.roleEntityRequired() || !this.user || !this.user.role) {
        return null;
      } else {
        let orgLookup: Models.IOrgLookup;
        let dealerLookup: Models.IDealerLookup;
        if (this.configService.role.isOrg3RoleId(this.user.role.roleId)) {
          orgLookup = this.orgLookups.filter(ol => ol.orgCode3 === control.value)[0];
        } 
        else if (this.configService.role.isOrg2RoleId(this.user.role.roleId)) {
          orgLookup = this.orgLookups.filter(ol => ol.orgCode2 === control.value)[0];
        } 
        else if (this.configService.role.isOrg1RoleId(this.user.role.roleId)) {
          orgLookup = this.orgLookups.filter(ol => ol.orgCode1 === control.value)[0];
        } 
        else if (this.configService.role.isDealerGroupRoleId(this.user.role.roleId)) {
          orgLookup = this.dealerGroupLookups.filter(ol => this.configService.org.orgConfig.dealerGroupOrgLookupTypeIds.indexOf(ol.orgLookupTypeId) != -1 && ol.orgCode1 === control.value)[0];
        }
        else {
          dealerLookup = this.dealerLookups.filter(ol => ol.dealerCode === control.value)[0];
        }
        return (orgLookup || dealerLookup) ? null : { 'noRoleEntityMatch': true };
      }
    };
  }

  public save(): void {
    const roles: string[] = [this.user.role.name];
    const userInfo: Models.IUser = <Models.IUser>{
      userName: this.user.userName,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      active: this.user.active,
      password: this.password,
      confirmPassword: this.confirmPassword,
      roles: roles,
      roleEntity: this.user.roleEntity,
      title: this.user.role.name,
      orgLookupTypeIds: this.userOrgLookupTypeIds.value,
      locale: this.userLocale.value,
    };


    if (this.isNew) {
      this.subscriptions.push(
        this.authService.register(userInfo)
          .pipe(tap(() => this.dialogRef.close(true))).subscribe()
      );
    } else {
      if (this.password !== this.currentPassword) {
        if (this.hideCurrentPassword()) {
          this.subscriptions.push(
            this.authService.resetPassword(this.user.userName, this.password, this.confirmPassword)
              .pipe(tap(() => this.dialogRef.close(true))).subscribe()
          );
        } else {
          this.subscriptions.push(
            this.authService.changePassword(this.currentPassword, this.password, this.confirmPassword)
              .pipe(tap(() => this.dialogRef.close(true))).subscribe()
          );
        }
      } else {
        if (this.userDetailsForm.dirty) {
          this.subscriptions.push(
            this.authService.updateUser(userInfo)
              .pipe(tap(() => this.dialogRef.close(true))).subscribe()
          );
        } else {
          this.dialogRef.close(true);
        }
      }
    }
  }
}
