import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Enums, IEnums } from '../../enums/enums';
import { FilterOptions, FilterActions } from '../../filter/store';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { INameValuePair } from '../../models/models';
import * as Models from '../../models/models-index';

import { SharedHelpTextModalComponent } from '../../modals/modals-index';
import { DataTableService } from '../../services/services-index';
import { IMetricConfig } from '../../models/models-index';

@Component({
  selector: 'sd-card-header-v2',
  templateUrl: './card-header-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardHeaderV2Component {
  @ViewChild('tableOptions', { static: true }) public ngSelect: MatSelect;
  @Input() title: string;
  @Input() iconClass: string;
  @Input() helpTextKey: string;
  @Input() helpTextTitle: string;
  @Input() exportName: string;
  @Input() metricDisplayModes: string[];
  @Input() defaultMetricId: number;
  @Input() availableMetrics: IMetricConfig[];
  @Input() set metricSelected(defaultMetricId: number)
  {
    this.metricSelect$.next(this.availableMetrics.find(m => m.id === defaultMetricId));
  }
  @Input() codeSlicers: INameValuePair[];
  @Input() defaultVisualizationCode: string;
  @Input() locale: string;
  @Output() expandClicked = new EventEmitter();
  @Output() metricSelectionChanged = new EventEmitter();

  private metricSelect$ = new BehaviorSubject<IMetricConfig>(null);

  tableOptions$: Observable<FilterOptions>;
  selectedTableOptions: string[];
  enums: IEnums = Enums;
  isTrend = false;
  isComp = false;
  subscriptions: Subscription[] = [];
  metricSelectConfig: Models.IMetricSelectConfig = null;
  // visualizationSelectConfig: Models.IVisualizationSelectConfig = null;

  constructor(
    private dataTableService: DataTableService,
    private dialog: MatDialog,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    if(!!this.defaultMetricId){
      this.generateMetricSelectInputs();
    }
    if(!!this.defaultVisualizationCode){
      this.generateVisualizationSelectInputs();
    }
  }

  generateMetricSelectInputs() {
    this.metricSelectConfig = {
      defaultMetricId: this.defaultMetricId,
      options: this.availableMetrics
    };
  }

  generateVisualizationSelectInputs() {
    // this.visualizationSelectConfig = {
    //   defaultMetricId: this.defaultMetricId,
    //   options: this.availableMetrics
    // };
  }

  applyMetricChange($event) {
    this.metricSelectionChanged.emit($event);
  }

  // jmstodo: after talking with james:
  // break into separate components - an actual header that takes in templates - ng template --
  // source be several card header components
  // card template - takes in a header, takes in the card
  // don't change direction right now - can extract this to get it where we want -- BUT pass in the config properties one at a time
  // ngOnInit() {

  //     // this.tableOptions$ = this.store$.select(FilterSelectors.selectOptions).pipe(
  //     //     map(options => {
  //     //         const selectedOptions: string[] = [];

  //     //         if (options.includeMOM) {
  //     //             selectedOptions.push('MOM');
  //     //         }
  //     //         if (options.includeYOY) {
  //     //             selectedOptions.push('YOY');
  //     //         }
  //     //         if (options.includeTrend) {
  //     //             selectedOptions.push('Trend');
  //     //         }
  //     //         if (options.includeLookback) {
  //     //             selectedOptions.push('Lookback');
  //     //         }
  //     //         if (options.includeAdvanced) {
  //     //             selectedOptions.push('Advanced');
  //     //         }

  //     //         this.selectedTableOptions = selectedOptions;
  //     //         this.isComp = this.isCompMode(this.selectedTableOptions);
  //     //         this.isTrend = this.isTrendMode(this.selectedTableOptions);
  //     //         return options;
  //     //     }
  //     // )), takeUntil(this.router.events);
  // }

  toggleExpanded($event) {
    this.expandClicked.emit($event)
  }

  updateTableScenarios(change: MatSelectChange) {

    const isTrend = this.isTrendMode(change.value);

    const options: FilterOptions = {
      includeAdvanced: !isTrend ? change.value.includes('Advanced') : false,
      includeLookback: !isTrend ? change.value.includes('Lookback') : false,
      includeMOM: !isTrend ? change.value.includes('MOM') : false,
      includeYOY: !isTrend ? change.value.includes('YOY') : false,
      includeTrend: isTrend ? change.value.includes('Trend') : false
    };

    this.store$.dispatch(FilterActions.updateOptions({ options }));
  }

  openHelpText(key: string, title: string): void {
    this.dialog.open(SharedHelpTextModalComponent, {
      width: '50vw',
      data: {
        title: title,
        name: key,
        locale: this.locale
      }
    });
  }

  isTrendMode(value: any) {
    return this.metricDisplayModes.includes('Trend') && value.includes('Trend');
  }

  isCompMode(value: any) {
    return value.includes('Advanced')
      || value.includes('Lookback')
      || value.includes('MOM')
      || value.includes('YOY');
  }

  printExcel(title: string, expandAll: boolean): void {
    this.dataTableService.printCurrentData({ title: title, expandAll: expandAll, printingOption: this.enums.printingOptions.excel });
  }
}
