import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILocaleInfo } from '../../_shared/locale/locale.model';
import { LocaleService } from '../../_shared/locale/locale.service';
import { SpinnerService } from '../../_shared/services/spinner/spinner.service';
import { Store, select } from '@ngrx/store';
import { AppState, LocaleState } from '../../_store/app-state.model';
import { AppSelectors } from '../../_store/selector-types';

@Component({
    selector: 'sd-language-selector',
    templateUrl: './language-selector-modal.component.html',
    styleUrls: ['./language-selector-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LanguageSelectorModalComponent {
  locale$: Observable<string>;
  localeState$: Observable<LocaleState>;

  constructor(
    private store$: Store<AppState>,
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<LanguageSelectorModalComponent>,
    private spinner: SpinnerService
  )   {
        // this.locale$ = this.localeService.locale$.pipe(map(loc => loc));
        this.localeState$ = this.store$.select(AppSelectors.selectLocaleState);
      }

    english(localeState: LocaleState) {
      this.spinner.show();
      
      const localeInfo: ILocaleInfo = {
        locale: 'en',
        userDefaultLocale: localeState.userDefaultLocale
      };
      this.localeService.registerCulture(localeInfo);
      window.location.reload();
    }

    french(localeState: LocaleState) {
      this.spinner.show();
      const localeInfo: ILocaleInfo = {
        locale: 'fr-CA',
        userDefaultLocale: localeState.userDefaultLocale
      };
      this.localeService.registerCulture(localeInfo);
      window.location.reload();
    }

    closeModal() {
      this.dialogRef.close();
    }
  }
