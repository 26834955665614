import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Enums } from '../../enums/enums';
import { BillboardConfig } from './overview-billboard-v2.models';

@Component({
  selector: 'sd-overview-billboard-v2',
  templateUrl: './overview-billboard-v2.component.html',
  styleUrls: ['./overview-billboard-v2.component.scss']
})
export class OverviewBillboardV2Component implements OnInit {

  @Input() config: BillboardConfig;

  @Output() openHelpText = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  getTrendArrowIconClass(trend: number): string {
    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }

  //getTrendColor(trend: number): string {
  //  if (!!this.config.metricTrendInverted) {
  //    // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
  //    if (trend <= 0) {
  //      return Enums.trendColors.green.hexString;
  //    } else {
  //      return Enums.trendColors.red.hexString;
  //    }
  //  } else {
  //    if (trend >= 0) {
  //      return Enums.trendColors.green.hexString;
  //    } else {
  //      return Enums.trendColors.red.hexString;
  //    }
  //  }
  //}
  getTrendColor(trend: number): string {
    // JMSTODO: I would prefer trend colors to be inputs, but that makes more lines elsewhere
    if (!!this.config.metricTrendInverted) {
      // For metrics like bounce rate where a reduction in the metric value is a positive indicator of performance
      if (!trend) {
        return '';
      } else if (trend <= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    } else {
      if (!trend) {
        return '';
      } else if (trend >= 0) {
        return 'text-green';
      } else {
        return 'text-red';
      }
    }
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }

}
