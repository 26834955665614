import { Component, OnInit, OnDestroy, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import * as Models from '../../models/models-index';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'metric-simple-select',
    templateUrl: './metric-simple-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MetricSimpleSelectComponent implements OnDestroy, OnInit {

    @Input() config: Models.IMetricSimpleSelectConfig;
    @Output() selectionChange: EventEmitter<Models.ITrendGraphMetricPropertyConfig> = new EventEmitter<Models.ITrendGraphMetricPropertyConfig>();

    constructor( ) {
    }

  ngOnInit(): void {
        this.emitSelectedMetric(this.config.defaultMetricId);
    }

    ngOnDestroy(): void {

    }

    applyMetricChange(evt: MatSelectChange) {
        this.emitSelectedMetric(evt.value);
    }

    emitSelectedMetric(selectedMetricId: number) {
      if(!!selectedMetricId){
        const selectedMetric = this.config.options.find(o => o.id === selectedMetricId);
        this.selectionChange.emit(selectedMetric);
      }
    }
}
