import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { IEnums, Enums } from '../../enums/enums';
import * as Models from '../../../_shared/models/models-index';
import { LayoutHelperService } from '../../../_layout/layout-index';
import { Subscription, Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { SeriesOptionsType } from 'highcharts';
import * as constants from '../../constants/constants';
import { FormattingService } from '../../services/services-index';
import { tap } from 'rxjs/operators';
import { ChartService } from '../chart/chart.service';

@Component({
    selector: 'dual-line-chart',
    templateUrl: './dual-line-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DualLineChartComponent implements OnDestroy, OnInit {

    @Input('chartConfig') set chartConfig(chartConfig: Models.ITrendGraph) {
        this.chartConfig$.next(chartConfig);
    }

    @Input('metricOneConfig') set metricOneConfig(metricOneConfig: Models.ITrendGraphMetricPropertyConfig) {
        this.metricOneConfig$.next(metricOneConfig);
    }

    @Input('metricTwoConfig') set metricTwoConfig(metricTwoConfig: Models.ITrendGraphMetricPropertyConfig) {
        this.metricTwoConfig$.next(metricTwoConfig);
    }

    @Input() locale = 'en';

    @ViewChild('sdChart', { static: false }) chartRef: ElementRef;

    private chartConfig$ = new BehaviorSubject<Models.ITrendGraph>(null);
    private metricOneConfig$ = new BehaviorSubject<Models.ITrendGraphMetricPropertyConfig>(null);
    private metricTwoConfig$ = new BehaviorSubject<Models.ITrendGraphMetricPropertyConfig>(null);

    enums: IEnums = Enums;
    subscriptions: Subscription[] = [];
    currentChart$: Observable<Chart>;
    currentChart: Chart; // for reference - reflow needs this

    chartMetricOne: Models.ITrendGraphMetricPropertyConfig;
    chartMetricTwo: Models.ITrendGraphMetricPropertyConfig;
    chartSeries: SeriesOptionsType[];

    constructor(
        private chartService: ChartService,
        private layoutService: LayoutHelperService,
        private formattingService: FormattingService) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.layoutService.sideNavToggleAction$.subscribe(navState => {
                setTimeout(() => { this.reflowChart(); }, navState === 'open' ? 20 : 30);
            })
        );

        this.subscriptions.push(
            combineLatest([this.chartConfig$, this.metricOneConfig$, this.metricTwoConfig$])
                .subscribe(([cc, mOne, mTwo]) => {
                    this.currentChart = this.updateChartSeries(cc, mOne, mTwo);
                    this.currentChart$ = of(this.currentChart);
                }),
            this.chartService.reflowChart$.pipe(
                tap(() => {
                    this.reflowChart();
                })
            ).subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    reflowChart() {
        if (this.currentChart && this.currentChart.ref) {
            this.currentChart.ref.reflow();
        }
    }

    getMetricFormatter(formatKey: string, loc: string) {
        if (formatKey === constants.formatKeys.percentageTwoDecimals) {
            return (val) => this.formattingService.percentPipeTwoDecimals(val, loc);
        } else if (formatKey === constants.formatKeys.minutesTimeStringFromSeconds) {
            return (val) => this.formattingService.minutesTimeStringFromSeconds(val);
        } else if (formatKey === constants.formatKeys.minutesTimeString) {
            return (val) => this.formattingService.minutesTimeString(val);
        } else if (formatKey === constants.formatKeys.roundToHundreth) {
            return (val) => this.formattingService.roundToHundredth(val, loc);
        } else if (formatKey === constants.formatKeys.roundToTenth) {
            return (val) => this.formattingService.roundToTenth(val, loc);
        } else {
            return (val) => this.formattingService.localeString(val, loc);
        }
    }

    private updateChartSeries(
        graphDataObject: Models.ITrendGraph,
        metricOneConfig: Models.ITrendGraphMetricPropertyConfig,
        metricTwoConfig: Models.ITrendGraphMetricPropertyConfig
        ) {

        // this is dirty...need to find a cleaner way to make sure this scenario never happens
        if (!metricOneConfig || !metricTwoConfig) {
            return;
        }

        // localization
        const chartSeriesOneData = graphDataObject.metrics.find(m => m.metricId === metricOneConfig.id).data;
        const chartSeriesTwoData = graphDataObject.metrics.find(m => m.metricId === metricTwoConfig.id).data;

        // get formatter for metric values based on configuration format key
        const metricOneFormatter = this.formattingService.getFormatter(metricOneConfig.metricFormatKey, this.locale);
        const metricTwoFormatter = this.formattingService.getFormatter(metricTwoConfig.metricFormatKey, this.locale);

        this.chartSeries = [{
            name: metricOneConfig.displayName,
            type: 'spline',
            yAxis: 0,
            data: chartSeriesOneData,
            color: graphDataObject.metricOneColorHexCode,
            marker: { symbol: 'circle' },
            lineWidth: 3
        }, {
            name: metricTwoConfig.displayName,
            type: 'spline',
            yAxis: 1,
            data: chartSeriesTwoData,
            color: graphDataObject.metricTwoColorHexCode,
            marker: { symbol: 'circle' },
            lineWidth: 3
        }];

        return new Chart({
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'xy',
                height: 308
            },
            title: {
                text: ''// this.getChartTitle(this.chartMetricOne, this.chartMetricTwo)
            },
            xAxis: [{
                categories: graphDataObject.dates,
                crosshair: true
            }],
            // AXES.....
            yAxis: [{ // Primary yAxis
                labels: {
                    formatter: function() { return metricOneFormatter(this.value); },
                    style: {
                        color: graphDataObject.metricOneColorHexCode
                    },
                },
                title: {
                    text: metricOneConfig.displayName,
                    style: {
                        color: graphDataObject.metricOneColorHexCode
                    }
                }
            }, { // Secondary yAxis
                labels: {
                    formatter: function() { return metricTwoFormatter(this.value); },
                    style: {
                        color: graphDataObject.metricTwoColorHexCode
                    }
                },
                title: {
                    text: metricTwoConfig.displayName,
                    style: {
                        color: graphDataObject.metricTwoColorHexCode
                    }
                },
                opposite: true
            }],
            tooltip: {
                formatter: function () {
                    /* Have to do some sinning here to get highcharts to work...doesn't work if we try to abstract this into the traffic service :( */
                    let s = '<span style="font-size: 10px">' + this.x + '</span><br/>';

                    for (let i = 0; i < this.points.length; i++) {

                        const myPoint = this.points[i];
                        const metricFormatter = myPoint.series.name === metricOneConfig.displayName ? metricOneFormatter : metricTwoFormatter;

                        s += `<br/><span style="color:${myPoint.series.options.color}">\u25CF </span>` + myPoint.series.name + ': ';
                        s += metricFormatter(myPoint.y);
                    }

                    return s;
                },
                shared: true
            },
            legend: {
                align: 'center',
                x: 0,
                verticalAlign: 'top',
                y: 0,
                floating: false,
                backgroundColor: 'white',
                borderColor: '#CCC',
                borderWidth: 0,
                itemMarginTop: 10,
                shadow: false
            },
            series: this.chartSeries
        });
    }
}
