import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../_shared/services/authentication/authentication.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { LAYOUT_ANIMATIONS } from './layout.animations';
import { ConfigurationService, ExportsService, SpinnerService } from '../_shared/services/services-index';
import { Enums, IEnums } from '../_shared/enums/enums';
import { LayoutHelperService } from './layoutHelper.service';
import { Router, NavigationStart } from '@angular/router';
import * as Models from '../_shared/models/models-index';
import { Store, select } from '@ngrx/store';
import { States } from '../_store/app.reducer';
import { selectLoggedInUser } from '../_store/app.selectors';
import { AppSelectors } from '../_store/selector-types';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import * as jsPDF from "jspdf";
import html2canvas, { Options } from 'html2canvas';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  animations: LAYOUT_ANIMATIONS
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('printArea') printArea: ElementRef;

  public loading$: Observable<boolean>;
  subscriptions: Subscription[] = [];

  secondaryNavState = 'open';
  defaultSidebarState = !!environment.sidebarDefaultOpen ? 'closed' : 'open'; // this is reversed intentionally so it works right
  enums: IEnums = Enums;
  authInfo: Models.IAuthenticationInfo;
  authInfo$ = this.store$.pipe(select(selectLoggedInUser));
  currentRouteData$ = this.store$.pipe(select(AppSelectors.selectCurrentRouteData));
  showFilterBar$: Observable<boolean>;
  showBreadcrumb$ = this.currentRouteData$.pipe(
    map((routeData) => !routeData.hideBreadcrumbs)
  );

  constructor(
    private store$: Store<States.AppState>,
    private configService: ConfigurationService,
    public layoutHelper: LayoutHelperService,
    private exportsService: ExportsService,
    private spinnerService: SpinnerService,
    private router: Router) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.layoutHelper.toggleSidebar(this.defaultSidebarState);
        }
      }),
      this.authInfo$.subscribe(authInfo => this.authInfo = authInfo),
      this.exportsService.exportButtonClicked$.subscribe(printingOption => {
        if (printingOption === this.enums.printingOptions.pdf) {
          this.printToPdf();
        }
      }),
    );
    this.layoutHelper.toggleSidebar(this.defaultSidebarState);
    this.layoutHelper.updateCurrentCategory();

    this.showFilterBar$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      map(routeData => {
        const config = this.configService.filter.filterConfig.filterReportConfigs[routeData.reportName];
        return ((config?.filters || []).length > 0) && (routeData.hideFilters != true);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  printToPdf() {
    this.spinnerService.show();
    setTimeout(() => {
      this.generatePdf();
    }, 200);
  }

  generatePdf() {
    const div = document.body;
    const options = {
      filter: (element: Element) => element.id != 'spinner'
    };

    domtoimage.toPng(div, options).then((dataUrl) => {
      html2canvas(div).then(canvas => {
        const aspectRatio = canvas.width / canvas.height;
        const doc = new jsPDF('p', 'in', 'letter');
        doc.addImage(dataUrl, 'JPEG', 0, 0, 8.5, 8.5 / aspectRatio);

        this.store$.select(AppSelectors.selectCurrentRouteData).pipe(take(1)).subscribe(routeData => {
          const reportName = routeData.reportName || 'Report';
          doc.save(`${reportName}.pdf`);
          this.spinnerService.hide();
        })
      });
    });
  };
}
