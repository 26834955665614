import { Component, ViewChild, Input, OnChanges, Output, EventEmitter, OnInit, DoCheck, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableService, FilterService, LocaleService } from '../../_shared/services/services-index';
import { Subscription } from 'rxjs';
import { Enums } from '../enums/enums';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { OrgFilterValue } from '../filter/filter.model';
import { ExpandableTable } from './expandableTable';
import { IDefaultTableColumn, IExpandableTable } from '../models/models';
import * as SharedServices from '../../_shared/services/services-index';
import { ConfigurationService } from '../services/config/config.service';
import * as Models from '../../_shared/models/models-index';

@Component({
  selector: 'hierarchy-data-table',
  templateUrl: 'hierarchyDataTable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyDataTableComponent extends ExpandableTable implements IExpandableTable, OnChanges, OnInit, OnDestroy {
  @Input() columns: any[] = [];
  @Output() drillThroughChange = new EventEmitter<OrgFilterValue>();
  displayedColumns: string[];
  filterModel: Models.IFilterModel;
  expanded: boolean = false;
  receivedData: any;
  showMom = false;
  showYoy = false;
  dataSource: MatTableDataSource<any>;
  filterBreadcrumbsForExport: string[];
  subscriptions: Subscription[] = [];
  locale: string;
  orgLookupTypeId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageIndex = 0;
  pageSize = 25;
  length;

  constructor(
    private dataTableService: DataTableService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService,
    private configService: ConfigurationService,
    private localeService: LocaleService,
    private router: Router,
    private sharedTranslationService: SharedServices.SharedTranslationService,
  ) {
    super();
    this.columns = [];
    this.receivedData = [];
    this.displayedColumns = this.columns.map(x => x.columnDef);
    this.dataSource = new MatTableDataSource(this.receivedData.filter(row => row.show));
  }

  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);

  toggleExpanded(expanded?: boolean) {
    this.expanded = (expanded || !this.expanded);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.expanded = this.dataTableService.heightExpanded;
    this.subscriptions.push(
      this.filterService.filter$.subscribe(filter => {
        this.filterModel = filter;
      }),
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.filterService.filter$.subscribe(olt => this.orgLookupTypeId = olt.orgLookupTypeId ?? 1),
      this.dataTableService.toggleHierarchyTableExpando$.subscribe(settings => {
        if (settings.expandType === 'height') {
          this.toggleExpanded(settings.expanded)
        }
      }),

      this.dataTableService.dataUpdated$.subscribe(updatedData => {
        this.receivedData = [];
        this.receivedData = updatedData.data || [];
        this.dataSource = new MatTableDataSource(this.receivedData.filter(row => row.show));
        this.columns = Object.assign([], updatedData.columns) || [];
        this.displayedColumns = this.columns.map(x => x.columnDef);
        this.changeDetector.markForCheck();
      }),
      this.dataTableService.selectedTrendUpdated$.subscribe(selectedTrendMetrics => {
        this.applyTableTrendUpdate(selectedTrendMetrics);
      }),
      this.dataTableService.printButtonClicked$.subscribe(data => {
        if (data.printingOption === Enums.printingOptions.pdf) {
          console.log('PDF exporting is not supported in hierarchyDataTable');
        } else {
          this.filterService.requestBreadcrumbString();
          this.excel(data.title, data.expandAll);
        }
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );
  }

  ngOnChanges() {

  }

  ngOnDestroy(): void {
    this.displayedColumns = [];
    this.receivedData = [];
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  applyTableTrendUpdate(selectedTrendMetrics: string[]): void {
    this.showMom = selectedTrendMetrics.includes('MOM');
    this.showYoy = selectedTrendMetrics.includes('YOY');
    this.columns.filter(col => (col.isMom)).forEach(col => { col.show = this.showMom; });
    this.columns.filter(col => (col.isYoy)).forEach(col => { col.show = this.showYoy; });

    this.changeDetector.detectChanges();
  }

  updateReport(row) {
    const entity = row.entity;
    const entityType = row.entityType;
    const entityName = row.displayName;

    const orgFilterValue: OrgFilterValue = {levelTypeId: this.orgLookupTypeId, entityType: entityType, value: entity, display: entityName};

    this.drillThroughChange.emit(orgFilterValue);
  }

  toggleChildRows(clickedRow: any): void {
    /*
      We have the option to use entity/parentEntity, or entityKey/parentEntityKey as the row keys.  We should migrate everything to use the latter
      eventually, that way the display name (entity) is not being used as a display name and a row key...
  */
    const newData = this.receivedData;

    if (!clickedRow.entityKey) {
      // toggle all rows for whom the parent entity was clicked and there is no entity key defined
      newData.filter(row => row.parentEntity === clickedRow.entity)
        .forEach(row => {
          if(clickedRow.entity === 'Other') {
            if(clickedRow.parentEntity === 'Total') {
              if(clickedRow.vehicleMakeName !== null && clickedRow.vehicleMakeName === row.vehicleMakeName && row.entityType === 'year') {
                row.show = !row.show;
              } else if (clickedRow.vehicleModelName !== null && clickedRow?.vehicleMakeName === row.vehicleMakeName && clickedRow.vehicleModelName === row.vehicleModelName && row.entityType === 'year') {
                row.show = !row.show;
              } else if(clickedRow.vehicleClassName !== null && clickedRow?.vehicleMakeName === row.vehicleMakeName && clickedRow?.vehicleModelName === row.vehicleModelName && clickedRow.vehicleClassName === row.vehicleClassName && row.entityType === 'year') {
                row.show = !row.show;
              }
            } else if(row?.vehicleMakeName === clickedRow.parentEntity) {
                row.show = !row.show;
            } else if (row?.vehicleModelName === clickedRow.parentEntity) {
                row.show = !row.show;
            } else if (row?.vehicleClassName === clickedRow.parentEntity) {
                row.show = !row.show;
            }
          } else {
            row.show = !row.show;
          }
          // if hiding a row, call a function to recursively hide children
          if (!row.show) {
            this.hideChildren(row);
          }
        });

      const currentShowChildrenState = newData.filter(row => row.entity === clickedRow.entity)[0].showChildren;

      newData.filter(row => row.entity === clickedRow.entity)[0].showChildren = !currentShowChildrenState;

      this.dataSource = new MatTableDataSource(newData.filter(row => row.show));
    } else {
      // toggle all rows for whom the parent entity was clicked and we have an entity key defined
      newData.filter(row => row.parentEntityKey === clickedRow.entityKey)
        .forEach(row => {
          row.show = !row.show;
          // if hiding a row, call a function to recursively hide children
          if (!row.show) {
            this.hideChildren(row);
          }
        });

      const currentShowChildrenState = newData.filter(row => row.entityKey === clickedRow.entityKey)[0].showChildren;

      newData.filter(row => row.entityKey === clickedRow.entityKey)[0].showChildren = !currentShowChildrenState;

      this.dataSource = new MatTableDataSource(newData.filter(row => row.show));
    }
  }

  hideChildren(row: any) {

    if (row.hasChildren) {
      row.showChildren = false;
      this.receivedData.filter(child => child.parentEntity === row.entity).forEach(child => { child.show = false; this.hideChildren(child); });
    }
  }

  customTrackBy(index, item) { return item.key; }

  private excel(title: string, expandAll: boolean = false): void {
    let data: string[][] = [[title]];

    // data.push(['Filters:', this.filterBreadcrumbsForExport]);
    // data.push(['Filters:']);

    (this.filterBreadcrumbsForExport || []).forEach(bc => {
      data.push([bc]);
    });

    const cols = this.columns.filter(col => ((col.print === undefined && col.show)
      || (col.print && this.showDealerNameColumn(expandAll))
      || (!this.dealerNameEnabled() && col.print)))
      .map(col => col.header);

    data.push(['']);
    data.push(cols);
    data = data.concat(this.getRowsForExport(expandAll, title));

    const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

    new Angular5Csv(data, printTitle);
  }

  private getRowsForExport(expandAll: boolean = false, title: string): string[][] {
    const results: string[][] = [];

    const columns = this.columns.filter((col) => {
      if (((col.print === undefined && col.show) || (col.print && this.showDealerNameColumn(expandAll)) || (!this.dealerNameEnabled() && col.print))) {
        return true;
      } else {
        return false;
      }
    });

    const rowData = !expandAll ? this.dataSource.filteredData : this.receivedData;
    const isDealer = this.configService.role.isDealerRole(this.filterModel.roleLevel);


    rowData.forEach(dataRow => {
      const exportRow: string[] = [];
      columns.forEach(col => {
        // Show entity display name in first column - except for dealers
        if (col.columnDef === 'entity') {
          if (dataRow['entityType'] !== 'dealer') {
            col.printFormatter ? exportRow.push(this.labelTranslator(col.printFormatter(dataRow['displayName']))) : exportRow.push(this.labelTranslator(dataRow['displayName'] || ''));
          } else {
            if(this.filterModel.dealerCode === null)
              exportRow.push(dataRow['entity']);
            else
              exportRow.push('');
          }
        } else if (this.dealerNameEnabled() && col.columnDef === 'displayName') {
          if (dataRow['entityType'] === 'dealer') {
            col.exportFormatter ? exportRow.push(col.exportFormatter(dataRow['displayName'])) : exportRow.push(dataRow['displayName'] || '');
          } else {
            exportRow.push('');
          }
        } else {
          col.printFormatter
            ? exportRow.push(col.printFormatter(dataRow[col.columnDef]))
            : col.formatter
              ? exportRow.push(col.formatter(dataRow[col.columnDef]))
              : exportRow.push(dataRow[col.columnDef] || '');
          //col.formatter ? exportRow.push(col.formatter(dataRow[col.columnDef])) : exportRow.push(dataRow[col.columnDef] || '');
        }
      });
      results.push(exportRow);
    });

    return results;
  }

  private showDealerNameColumn(expandAll: boolean = false): boolean {
    const filteredDataDealerExists = !!this.dataSource.filteredData.find(x => (x.entityType === 'dealer' && x.show === true));

    return this.dealerNameEnabled() && ((expandAll || filteredDataDealerExists));
  };

  private dealerNameEnabled(): boolean {
    const currentUrl = this.router.url;
    const urlsWithDealerNameEnabled: string[] = [
      '/inventory/overview',
      '/leads/overview',
      '/provider-tools',
      '/leads/summary',
      '/sales/summary',
      '/sales/vehicle',
      '/website/aftersales',
      '/website/chat',
      '/website/landingpage',
      '/website/overview',
      '/website/summary',
    ];

    return !!urlsWithDealerNameEnabled.find(u => u?.toUpperCase().includes(currentUrl?.toUpperCase()));
  }

  calculateClasses(column: IDefaultTableColumn, classes?: string[]) {

    // Classes passed in from the front end
    let manualClasses = {
      'hideRow': !column.show
    };

    (classes || []).forEach(mc => {
      manualClasses[mc] = true;
    })

    // Classes from the column config
    let configuredClasses = {};
    (column.columnClasses || []).forEach(cc => {
      configuredClasses[cc] = true;
    })
    // Anything passed into the function directly should be seen as a default that is overridable by configuration
    return {
      ...manualClasses,
      ...configuredClasses
    };
  }

}
