import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class UtilsService {

    constructor(
        private appInsights: AppInsightsService
    ) {}

    parseDateFieldValue(date: string|Object): string {
        if (typeof date === 'string') {
            return date.replace(/-/g, '/');
        } else {
            return `${date['month']}/${date['day']}/${date['year']}`;
        }
    }

    convertDateToDateOnlyString(date: Date): string {
        return date ? date.toLocaleDateString('en-US') : '';
    }

    convertDateStringToDateObject(date: string|Date, addTimeStamp: boolean = false): NgbDateStruct {
        let d: Date;

        if (typeof date === 'string') {
            const dateString: string = date;
            d = new Date(dateString);
        } else {
            d = date as Date;
        }

        const returnDate: NgbDateStruct = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };

        return returnDate;
    }

    handleHttpError(err: any) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else if (err.body) {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        } else {
            errorMessage = JSON.stringify(err);
        }

        this.appInsights.trackException(err);
        console.error(err);
        return throwError(errorMessage);
      }
}
