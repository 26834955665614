import { Injectable } from '@angular/core';
import { HttpEvent, HttpHeaders, HttpInterceptor, HttpHandler, HttpParams, HttpRequest, HttpResponse,
    HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { IAuthenticationData, IAuthenticationInfo } from '../../../models/models-index';
import { Enums, IEnums } from '../../../enums/enums';

import { Observable, throwError } from 'rxjs';
import { AppInsightsService } from '../../appInsights/appInsights.service';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptorService implements HttpInterceptor {
    private enums: IEnums = Enums;
    private user: IAuthenticationInfo;


        constructor(
            private router: Router,
            private authService: AuthenticationService,
            private appInsights: AppInsightsService) {
        }

        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(this.applyCredentials(request))
                .pipe(
                    catchError((err: any) => {
                        if (err instanceof HttpErrorResponse && err.status === 401) {
                            localStorage.removeItem('user');
                            console.error('Http Error', err);
                            this.router.navigateByUrl('/login');
                        } else {
                            this.appInsights.trackException(err);
                            return throwError(err);
                        }
                    })
                );
        }

        private applyCredentials(request: HttpRequest<any>) {
                this.user = this.authService.getUser();
                if (this.user) {
                        const headers = this.setHttpHeaders(this.user);
                        request = request.clone({ headers: headers/*, withCredentials: true*/ });
                }
                return request;
        }

        private setHttpHeaders(user: IAuthenticationInfo) {
                const myHeaders = new HttpHeaders()
                        .set('Access-Control-Allow-Origin', '*')
                        .set('Access-Control-Allow-Credentials', 'true')
                        .set('Content-Type', 'application/json')
                        .set('Accept', 'application/json')
                        .set('X-Auth-Token', user.token)
                        .set('Authorization', 'Bearer ' + user.token);

                return myHeaders;
        }

        private setDownloadHttpHeaders(authData: IAuthenticationData) {
                const myHeaders = new HttpHeaders()
                        .set('Content-Type', 'text/csv')
                        .set('Accept', 'text/csv')
                        .set('X-Auth-Token', authData.token)
                        .set('Authorization', 'Bearer ' + authData.token);

                return myHeaders;
        }

        private setUploadHttpHeaders(authData: IAuthenticationData) {
                const myHeaders = new HttpHeaders()
                        .set('X-Auth-Token', authData.token)
                        .set('Authorization', 'Bearer ' + authData.token);

                return myHeaders;
        }

        private trackException(exception: any) {
                // this.ai.trackException(exception);
        }
}
