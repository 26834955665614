import { Directive, HostListener, Input } from '@angular/core';
import { DataTableService } from '../services/services-index';

@Directive({
  selector: '[appToggleExpandoDataTable]'
})
export class ToggleExpandoDataTableDirective {
  @Input() dataTableType: any;
  @Input() scrollToElement: HTMLElement;
  @Input() expandType: string;

  constructor(private dataTableService: DataTableService) { }

  @HostListener('click', ['$event'])
  clickEvent(event) {

    event.preventDefault();
    event.stopPropagation();

    this.dataTableService.toggleExpando(this.dataTableType, this.expandType);

    if (!!this.scrollToElement) {
      this.scrollToElement.scrollIntoView();
    }
  }
}
