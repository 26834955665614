import { Injectable } from '@angular/core';
import { IOrgLookupType } from '../../../enums/enums';
import { IRoleConfig } from '../../../models/models-index';
import { RoleConfig } from '../../../../_config/role/role.config';

// @Injectable({ providedIn: 'root' })
@Injectable()
export class RoleConfigService {

  roleConfig: IRoleConfig = new RoleConfig;

  constructor() { }

  isSysAdminRoleId(roleId: number) {
    return this.roleConfig.systemAdministratorRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isSysAdminRole(role: string) {
    return this.roleConfig.systemAdministratorRoles.map(r => r.name).indexOf(role) != -1;
  }

  isCorporateRoleId(roleId: number) {
    return this.roleConfig.corporateRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isCorporateRole(role: string) {
    return this.roleConfig.corporateRoles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg3RoleId(roleId: number) {
    return this.roleConfig.org3Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg3Role(role: string) {
    return this.roleConfig.org3Roles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg2RoleId(roleId: number) {
    return this.roleConfig.org2Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg2Role(role: string) {
    return this.roleConfig.org2Roles.map(r => r.name).indexOf(role) != -1;
  }

  isOrg1RoleId(roleId: number) {
    return this.roleConfig.org1Roles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isOrg1Role(role: string) {
    return this.roleConfig.org1Roles.map(r => r.name).indexOf(role) != -1;
  }

  isDealerRoleId(roleId: number) {
    return this.roleConfig.dealerRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isDealerRole(role: string) {
    return this.roleConfig.dealerRoles.map(r => r.name).indexOf(role) != -1;
  }

  isDealerGroupRoleId(roleId: number) {
    return this.roleConfig.dealerGroupRoles.map(r => r.roleId).indexOf(roleId) != -1;
  }

  isDealerGroupRole(role: string) {
    return this.roleConfig.dealerGroupRoles.map(r => r.name).indexOf(role) != -1;
  }

  roleHasSysAdminAccess(role: string) {
    return this.isSysAdminRole(role);
  }

  roleHasCorporateAccess(role: string) {
    return this.roleHasSysAdminAccess(role)
      || this.isCorporateRole(role);
  }

  roleHasOrg3Access(role: string) {
    return this.roleHasCorporateAccess(role)
      || this.isOrg3Role(role);
  }

  roleHasOrg2Access(role: string) {
    return this.roleHasOrg3Access(role)
    || this.isOrg2Role(role);
  }

  roleHasOrg1Access(role: string) {
    return this.roleHasOrg2Access(role)
    || this.isOrg1Role(role);
  }

  roleHasDealerAccess(role: string) {
    return this.roleHasOrg1Access(role)
    || this.isDealerRole(role);
  }

}
