import { Component, ViewChild, Input, OnChanges, Output, EventEmitter, OnInit, DoCheck, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableService, FilterService, FormattingService } from '../../_shared/services/services-index';
import { Subscription, combineLatest } from 'rxjs';
import { Enums } from '../enums/enums';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { PercentagePipe } from '../pipes/percentage.pipe';
import { AppState } from '../../_store/app-state.model';
import { Store } from '@ngrx/store';
import { AppSelectors } from '../../_store/selector-types';

@Component({
  selector: 'trend-data-table',
  templateUrl: 'trendDataTable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendDataTableComponent implements OnDestroy, OnChanges, OnInit {
  @Input() displayedColumns: string[];
  @Input() columns: any[] = [];
  @Input() pagination: boolean;

  expanded: boolean = false;
  receivedData: any;
  showMom = false;
  showYoy = false;
  dataSource: MatTableDataSource<any>;
  filterBreadcrumbsForExport: string[];
  subscriptions: Subscription[] = [];
  locale: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageIndex = 0;
  pageSize = 25;
  length;

  constructor(
    private store$: Store<AppState>,
    private dataTableService: DataTableService,
    private formattingService: FormattingService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService
  ) {
    this.columns = [];
    this.receivedData = [];
    this.displayedColumns = this.columns.map(x => x.rowKey);
    this.dataSource = new MatTableDataSource(this.receivedData.filter(row => row.show));
    // this.dataSource = new MatTableDataSource(this.receivedData);
  }

  toggleExpanded(expanded?: boolean) {
    this.expanded = (expanded || !this.expanded);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.expanded = this.dataTableService.heightExpanded;
    this.subscriptions.push(
      this.dataTableService.toggleTrendTableExpando$.subscribe(settings => {
        if (settings.expandType === 'height') {
          this.toggleExpanded(settings.expanded)
        }
      }),
      combineLatest([
        this.store$.select(AppSelectors.selectCurrentRouteData),
        this.dataTableService.dataUpdated$
      ]).subscribe(([_, updatedData]) => {
        this.columns = [];
        this.receivedData = [];
        this.receivedData = updatedData.data;
        this.dataSource = new MatTableDataSource(this.receivedData.filter(row => row.show));
        this.columns = updatedData.columns;
        this.displayedColumns = this.columns.map(x => x.rowKey);
        this.changeDetector.markForCheck();
      }),
      // this.dataTableService.selectedTrendUpdated$.subscribe(selectedTrendMetrics => {
      //   this.applyTableTrendUpdate(selectedTrendMetrics);
      // }), // JMSTODO: Why do we need this here? Maybe in hierarchy but not here ^^ we don't hit this when we get trend report
      this.dataTableService.printButtonClicked$.subscribe(data => {
        if (data.printingOption === Enums.printingOptions.pdf) {
          console.log('PDF exporting is not supported in hierarchyDataTable');
        } else {
          this.filterService.requestBreadcrumbString();
          this.excel(data.title, data.expandAll);
        }
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );
  }

  ngOnChanges() {

  }

  ngOnDestroy(): void {
    this.displayedColumns = [];
    this.receivedData = [];
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getFormatter(formatKey: string) {
    // Make this into an enum that corresponds to the API
    if (formatKey === 'percentage') {
      return (val) => PercentagePipe.prototype.transform(val);
    }
  }

  applyTableTrendUpdate(selectedTrendMetrics: string[]): void {
    this.showMom = selectedTrendMetrics.includes('MOM');
    this.showYoy = selectedTrendMetrics.includes('YOY');
    this.columns.filter(col => (col.isMom)).forEach(col => { col.show = this.showMom; });
    this.columns.filter(col => (col.isYoy)).forEach(col => { col.show = this.showYoy; });

    this.changeDetector.detectChanges();
  }

  toggleChildRows(clickedRow: any): void {
    /*
      We have the option to use entity/parentEntity, or entityKey/parentEntityKey as the row keys.  We should migrate everything to use the latter
      eventually, that way the display name (entity) is not being used as a display name and a row key...
  */
    const newData = this.receivedData;

    if (!clickedRow.entityKey) {
      // toggle all rows for whom the parent entity was clicked and there is no entity key defined
      newData.filter(row => row.parentEntity === clickedRow.entity)
        .forEach(row => {
          row.show = !row.show;

          // if hiding a row, call a function to recursively hide children
          if (!row.show) {
            this.hideChildren(row);
          }
        });

      const currentShowChildrenState = newData.filter(row => row.entity === clickedRow.entity)[0].showChildren;

      this.dataSource = new MatTableDataSource(newData.filter(row => row.show));
    } else {
      // toggle all rows for whom the parent entity was clicked and we have an entity key defined
      newData.filter(row => row.parentEntityKey === clickedRow.entityKey)
        .forEach(row => {
          row.show = !row.show;
          // if hiding a row, call a function to recursively hide children
          if (!row.show) {
            this.hideChildren(row);
          }
        });

      const currentShowChildrenState = newData.filter(row => row.entityKey === clickedRow.entityKey)[0].showChildren;

      newData.filter(row => row.entityKey === clickedRow.entityKey)[0].showChildren = !currentShowChildrenState;

      this.dataSource = new MatTableDataSource(newData.filter(row => row.show));
    }
  }

  hideChildren(row: any) {
    if (row.hasChildren) {
      row.showChildren = false;
      // this.receivedData.filter(child => child.parentEntityKey === row.entityKey).forEach(child => { child.show = false; this.hideChildren(child); });
      this.receivedData.filter(child => child.parentEntityKey === row.entityKey).forEach(child => { child.show = false; });
    }
  }

  showColumn = (col: any) => (col.print === undefined || !!col.print) && (!!col.show || col.show === undefined);

  private excel(title: string, expandAll: boolean = false): void {
    let data: string[][] = [[title]];

    (this.filterBreadcrumbsForExport || []).forEach(bc => {
      data.push([bc]);
    });

    const cols: string[] = this.columns.filter(this.showColumn).map(col => col.header || col.name);

    data.push(['']);
    data.push(cols);

    data = data.concat(this.getRowsForExport(expandAll));

    const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

    const _ = new Angular5Csv(data, printTitle);
  }

  private getRowsForExport(expandAll: boolean = false): string[][] {
    const results: string[][] = [];

    const rowData = !expandAll ? this.dataSource.filteredData : this.receivedData;
    rowData.forEach(dataRow => {
      const exportRow: string[] = [];
      // const translatedFormatKey = this.translateTrendFormatKey(dataRow['formatKey'], dataRow['dataNameKey']);
      const formatter = dataRow['printFormatKey'] ?? dataRow['formatKey'];
      this.columns.forEach(col => {
        if (col.rowKey === 'dataNameKey') {
          exportRow.push(dataRow['displayName']);
        } else {
          formatter ? exportRow.push( formatter(dataRow[col.rowKey]) ) : exportRow.push(dataRow[col.rowKey] || '0');
        }
      });
      results.push(exportRow);
    });

    return results;
  }
}
