import { Directive, HostListener, Input } from '@angular/core';
import { ExpandableTable } from '../dataTable/expandableTable';

@Directive({
  selector: '[appToggleExpandoCard]'
})
export class ToggleExpandoCardDirective {
  @Input() card: any;
  @Input() scrollToElement: HTMLElement;
  @Input() expandtype: string;

  constructor() { }

  @HostListener('click', ['$event'])
  clickEvent(event) {

    event.preventDefault();
    event.stopPropagation();

    if (this.expandtype === 'height') {
      if (this.card instanceof ExpandableTable) {
        this.card.toggleExpanded();
        this.scrollToElement.scrollIntoView();
      }
      else {
        
      }
    }
    else if (this.expandtype === 'overlay') {

    }
  }
}
