<mat-card [@transformMenu]="panelState" class="mat-card filter-panel-card mat-elevation-z8"
    style="width: 700px; height: 465px; padding: 0;">

    <div class="filter-panel-header">

        <div class="mat-card-header-content"
            style="height: 54px; margin: 0; padding: 0 20px; display: flex; align-items: center; justify-content: space-between; color: #fff;">

            <span class="mat-dialog-title" style="margin: 0">
                {{title}}
            </span>

            <span class="dialog-close-icon" style="font-size: 24px; cursor: pointer;" (click)="closePanel()">
                <mat-icon>close</mat-icon>
            </span>

        </div>

    </div>

    <mat-card-content class="filter-panel-content-container"
        style="height: 328px; max-height: 328px; width: 100%; margin: 0;">

        <ng-container [ngSwitch]="renderMethod">

            <div *ngSwitchCase="renderMethods.text" [innerHtml]="content"></div>

            <ng-container *ngSwitchCase="renderMethods.template">
                <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="renderMethods.component">
                <ng-container *ngComponentOutlet="content"></ng-container>
            </ng-container>

        </ng-container>

    </mat-card-content>

    <mat-card-footer class="filter-panel-footer"
        style="height: 65px; padding: 0 20px; display: flex; align-items: center; justify-content: flex-end; border-top: 1px solid #ccc; margin: 0;">

        <button [disabled]="applyButtonDisabled" mat-button class="apply-button" (click)="applySelected()">
            <span>{{applyButtonText}}</span>
        </button>

    </mat-card-footer>

</mat-card>
