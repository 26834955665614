import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Digital Retailing
        'DigitalRetailingOverview': {
          reportName: constants.reportNames.digitalRetailingOverview,
          dateMode: Enums.dateModes.currentMonth,
          filters: ['org', 'digital-retailing-provider', 'device-type'],
          defaults: ['org'],
          section: 'provider-tools',
          orgTypes: [1, 4]
        },
        // Trade-In
        'TradeInOverview': {
          reportName: constants.reportNames.tradeInOverview,
          dateMode: Enums.dateModes.currentMonth,
          filters: ['org', 'device-type'],
          defaults: ['org'],
          section: 'provider-tools',
          orgTypes: [1, 4]
        },
        // Service Scheduler
        'ServiceSchedulerOverview': {
            reportName: constants.reportNames.serviceSchedulerOverview,
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'device-type'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1, 4]
          },
        // Chat
        'ChatOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.chatOverview,
            filters: ['org', 'device-type'],
            defaults: ['org'],
            section: 'chat',
            orgTypes: [1, 4]
        },
        'ChatSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.chatSummary,
            filters: [
                'org'
            ],
            defaults: [
                'org'
            ],
            section: 'chat',
            orgTypes: [1, 4]
        },
        // Inventory
        'InventoryOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.inventoryOverview,
            filters: [
                'org', 'vehicle'
            ],
            defaults: [
                'org', 'vehicle'
            ],
            section: 'inventory'
        },
        // Leads
        'LeadsOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.leadsOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'leads'
        },
        'LeadSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadSummary,
            filters: [
                'org', 'lead-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadSourcePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadSourcePerformance,
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-make'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadEventPerformance': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.leadEventPerformance,
            filters: [
                'org', 'vehicle-make'
            ],
            defaults: [
                'org'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'FacebookPerformance': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.facebookPerformance,
            filters: [
                'org'
            ],
            defaults: [
                'org'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadVehiclePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadVehiclePerformance,
            filters: [
                'org', 'source-type', 'vehicle-make'
            ],
            defaults: [
                'org', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadTransactions': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadTransactions,
            filters: [
                'org', 'lead-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        // Sales
        'SalesOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'sales',
            orgTypes: [1]
        },
        'SalesSummary': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesSummary,
            filters: [
                'org', 'sale-type', 'lead-type', 'source-type', 'vehicle-class'
            ],
            defaults: [
                'org', 'sale-type', 'lead-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1]
        },
        'SalesVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesVehicleSummary,
            filters: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1]
        },
        // Website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteAfterSales': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteAfterSales,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteCertifiedChat': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteCertifiedChat,
            filters: [
                'org', 'chat-provider'
            ],
            defaults: [
                'org', 'chat-provider'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteLandingPage': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteLandingPage,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },

        'WebsiteProviderSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteProviderSummary, // org only
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteVehicleSummary,
            filters: [
                'org', 'website-provider', 'device-type'
            ],
            defaults: [
                'org', 'website-provider', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        // DigAd
        'DigitalAdvertisingOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingOverview,
            filters: ['org', 'channel'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingScorecard,
            filters: ['org', 'channel', 'digad-provider'],
            defaults: ['org', 'channel', 'digad-provider'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingSummary,
            filters: ['org', 'channel', 'digad-provider'],
            defaults: ['org', 'channel', 'digad-provider'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingProvider': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingProvider,
            filters: ['org', 'channel'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingChannel': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingChannel,
            filters: ['org', 'digad-provider'],
            defaults: ['org', 'digad-provider'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [2,12,15,16],
            selected: [
                { value: 15, display: 'ActiveEngage' },
                { value: 16, display: 'CarChat24' },
                { value: 12, display: 'CarNow' },
                { value: 2, display: 'Conversations' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            selected: [
                { value: 1, display: 'Brand' },
                { value: 2, display: 'Paid Search' },
                { value: 3, display: 'Organic Search' },
                { value: 4, display: 'Paid Display' },
                { value: 5, display: 'Paid Video' },
                { value: 6, display: 'Paid Email' },
                { value: 7, display: 'Organic Email' },
                { value: 8, display: 'Paid Social' },
                { value: 9, display: 'Organic Social' },
                { value: 10, display: 'Third Party Sites' },
                { value: 11, display: 'Typed/Bookmarked' },
                { value: 12, display: 'Other' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-provider': {
          type: 'digad-provider',
          available: [ 2,3,4,28,29 ],
          selected: [
            { value: 2, display: 'Dealer Inspire' },
            { value: 3, display: 'DealerON' },
            { value: 4, display: 'Dealer.com' },
            { value: 28, display: 'Pixel Motion' },
            { value: 29, display: 'All Auto Network' },
          ],
          removable: true,
          lockable: true,
          locked: false
        },
        'digital-retailing-provider': {
            type: 'digital-retailing-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Vehicle' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [{ value: 0, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            selected: [
                { value: 1, display: 'Brand' },
                { value: 3, display: 'Dealer Website' },
                { value: 5, display: 'Event' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [1, 2, 3, 4, 5, 9, 10, 11],
            selected: [
                { value: 1, display: 'DealerFire' },
                { value: 2, display: 'Dealer Inspire' },
                { value: 3, display: 'DealerOn' },
                { value: 4, display: 'Dealer.com' },
                { value: 5, display: 'Dealer eProcess' },
                { value: 9, display: 'Stream' },
                { value: 10, display: 'fusionZONE' },
                { value: 11, display: 'Dealer Venom' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },
    };
}
