import {
  IDefaultTableColumnConfig,
  IWebsiteVehicleConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteVehicleConfig implements IWebsiteVehicleConfig {
  reportTitle = 'Vehicle Summary';

  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.websiteVdpSummaryHelp,
    helpTextTitle: 'VDP Summary Help',
    exportName: 'VDP Summary - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.websiteMetrics.newVdpViews.metricId,
        displayName: Enums.websiteMetrics.newVdpViews.name,
        propertyName: Enums.websiteMetrics.newVdpViews.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.websiteMetrics.usedVdpViews.metricId,
        displayName: Enums.websiteMetrics.usedVdpViews.name,
        propertyName: Enums.websiteMetrics.usedVdpViews.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.websiteMetrics.cpoVdpViews.metricId,
        displayName: Enums.websiteMetrics.cpoVdpViews.name,
        propertyName: Enums.websiteMetrics.cpoVdpViews.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.websiteMetrics.otherVdpViews.metricId,
        displayName: Enums.websiteMetrics.otherVdpViews.name,
        propertyName: Enums.websiteMetrics.otherVdpViews.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.websiteMetrics.totalVdpViews.metricId,
        displayName: Enums.websiteMetrics.totalVdpViews.name,
        propertyName: Enums.websiteMetrics.totalVdpViews.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
    ],
    childMetrics: [], // this needs to be data driven

    defaultChildrenIds: ['All Models', 'All Models'],
    defaultParentIds: [Enums.websiteMetrics.newVdpViews.metricId, Enums.websiteMetrics.usedVdpViews.metricId],
    metricOneColorHexCode: '#000',
    metricTwoColorHexCode: '#F7971C'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Vehicle Model',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    {
      show: true,
      header: 'New VDP Views',
      columnDef: 'newVdpViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month New VDP Views',
      columnDef: 'newVdpViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'New VDP Views MOM',
      columnDef: 'newVdpViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year New VDP Views',
      columnDef: 'newVdpViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'New VDP Views YOY',
      columnDef: 'newVdpViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Used VDP Views',
      columnDef: 'usedVdpViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Used VDP Views',
      columnDef: 'usedVdpViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Used VDP Views MOM',
      columnDef: 'usedVdpViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Used VDP Views',
      columnDef: 'usedVdpViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Used VDP Views YOY',
      columnDef: 'usedVdpViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'CPO VDP Views',
      columnDef: 'cpoVdpViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month CPO VDP Views',
      columnDef: 'cpoVdpViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false

    },
    {
      show: false,
      header: 'CPO VDP Views MOM',
      columnDef: 'cpoVdpViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year CPO VDP Views',
      columnDef: 'cpoVdpViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'CPO VDP Views YOY',
      columnDef: 'cpoVdpViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Other VDP Views',
      columnDef: 'otherVdpViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Other VDP Views',
      columnDef: 'otherVdpViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Other VDP Views MOM',
      columnDef: 'otherVdpViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Other VDP Views',
      columnDef: 'otherVdpViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Other VDP Views YOY',
      columnDef: 'otherVdpViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Total VDP Views',
      columnDef: 'totalVdpViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Total VDP Views',
      columnDef: 'totalVdpViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total VDP Views MOM',
      columnDef: 'totalVdpViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Total VDP Views',
      columnDef: 'totalVdpViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total VDP Views YOY',
      columnDef: 'totalVdpViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
  ];
}
