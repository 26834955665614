import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import * as FilterModelTypes from './filter.model';
import { Enums, IDateMode, DateMonthModes, dateMonthModeLookup } from '../enums/enums';
import * as moment from 'moment';
import { DatePeriodInfoModel } from '../models/models';
import { AppState } from '../../_store/app-state.model';
import { ConfigurationService } from '../services/config/config.service';
import { AppSelectors } from '../../_store/selector-types';
import { map, filter, withLatestFrom, tap } from 'rxjs/operators';
import { Filter, FilterTypes, DateFilterValue } from './filter.model';
import { SharedTranslationService } from '../locale/translation/shared-translation.service'
import { LocaleService } from '../locale/locale.service';
import { DatePipe } from '@angular/common';
import * as constants from '../../_shared/constants/constants';

export interface DateRangeSet { months: DateFilterValue[]; quarters: DateFilterValue[]; scenarios: DateFilterValue[]; }
export type itemFormatExpression = (item: DateFilterValue) => string;

@Injectable({ providedIn: 'root' })
export class FilterDateService {

  public dateRanges$: Observable<DateRangeSet>;

  private salesMonthsEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public salesMonthsEnabled$ = this.salesMonthsEnabled.asObservable();
  locale: string;

  constructor(
    private store$: Store<AppState>,
    private configService: ConfigurationService,
    private sharedTranslationService: SharedTranslationService,
    private localeService: LocaleService,
    private datePipe: DatePipe
  ) {
    this.dateRanges$ = this.getDateRanges();
    this.localeService.locale$.pipe(tap(locale => this.locale = locale)).subscribe();
  }

  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);

  getDateRanges(): Observable<DateRangeSet> {
    return combineLatest([
        this.store$.select(AppSelectors.selectReportNameAndConfig),
        this.salesMonthsEnabled$])
        .pipe(
          filter(([current, salesMonthsEnabled]) => !!current.reportName && current.config.dateMonths?.length > 0),
          map(([current, salesMonthsEnabled]) => {
            // get report config
            const filterConfig = this.configService.filter.filterConfig;
            const reportConfig = filterConfig.filterReportConfigs[current.reportName];
            const monthDateMode: DateMonthModes = !!salesMonthsEnabled ? 'sales' : 'calendar';
            const orgDateCode: number = !!salesMonthsEnabled ? constants.orgDateCodes.sales : constants.orgDateCodes.calendar;
            const monthsToShow = (!!reportConfig ? reportConfig.numberOfMonthsToShow : null) || filterConfig.NUMBER_OF_MONTHS_TO_SHOW_DEFAULT || 12;
            const quartersToShow = (!!reportConfig ? reportConfig.numberOfQuartersToShow : null) || filterConfig.NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT || 8;

            const months = this.filterAndSortPeriods(monthDateMode, current.config.dateMonths);
            const quarters = this.filterAndSortPeriods(monthDateMode, current.config.dateQuarters);

            return {
              months: this.buildMonthOptions(months, monthsToShow),
              quarters: this.buildQuarterOptions(quarters, quartersToShow),
              scenarios: this.buildScenarioOptions(orgDateCode, months, monthsToShow)
            };
          })
    );
  }

  applyDateMonthMode(dateMonthMode: DateMonthModes) {
    this.salesMonthsEnabled.next(dateMonthMode == 'sales' ? true : false);
  }

  getDefaultDateFilter(): Observable<Filter> {
    return this.dateRanges$.pipe(
      filter(ranges => ranges?.months?.length > 0),
      map(ranges => {
        const currentMonth = ranges.months[0];
        const month = this.labelTranslator(this.datePipe.transform(currentMonth.startDate, 'MMMM'));
        const year = this.datePipe.transform(currentMonth.startDate, 'yyyy');
        currentMonth.displayName = `${month} ${year}`

        return {
          type: FilterTypes.date,
          selected: [currentMonth],
          visible: false,
          locked: true // TODO we might want to make this configurable
        };
      }));
  }

  /// Assumes the months being passed have already been sorted desc so the most recent month is the first item
  getModeDateRangeItem(orgDateCode: number, dateModeId: number, months: DatePeriodInfoModel[]) {
    let startDate: Date;
    let endDate: Date;
    let previousStartDate: Date;
    let previousEndDate: Date;
    let previousYearStart: Date;
    let previousYearEnd: Date;
    let displayName: string;

    switch (dateModeId) {
      case Enums.dateModes.lastThirtyDays.dateModeId:
        endDate = moment().startOf('day').subtract(1, 'day').toDate();
        startDate = moment(endDate).clone().subtract(30, 'day').toDate();
        previousEndDate = moment(startDate).clone().subtract(1, 'day').toDate();
        previousStartDate = moment(previousEndDate).clone().subtract(30, 'day').toDate();
        previousYearEnd = moment(endDate).clone().subtract(1, 'year').toDate();
        previousYearStart = moment(previousYearEnd).clone().subtract(30, 'day').toDate();
        displayName = this.labelTranslator('Last 30 Days');
        break;
      case Enums.dateModes.lastMonth.dateModeId:
        startDate = months[1].startDate;
        endDate = months[1].endDate;
        previousStartDate = months[2].startDate;
        previousEndDate = months[2].endDate;
        previousYearStart = months[13].startDate;
        previousYearEnd = months[13].endDate;
        displayName = this.labelTranslator('Last Month');
        break;
      case Enums.dateModes.previousThreeMonths.dateModeId:
        startDate = months[3].startDate;
        endDate = months[1].endDate;
        previousStartDate = months[6].startDate;
        previousEndDate = months[4].endDate;
        previousYearStart = months[15].startDate;
        previousYearEnd = months[13].endDate;
        displayName = this.labelTranslator('Last 3 Months');
        break;
      case Enums.dateModes.previousTwelveMonths.dateModeId:
        startDate = months[12].startDate;
        endDate = months[1].endDate;
        previousStartDate = months[24].startDate;
        previousEndDate = months[13].endDate;
        previousYearStart = months[36].startDate;
        previousYearEnd = months[25].endDate;
        displayName = this.labelTranslator('Last 12 Months');
        break;
      case Enums.dateModes.previousThirteenMonths.dateModeId:
          startDate = months[14].startDate;
          endDate = months[1].endDate;
          previousStartDate = months[26].startDate;
          previousEndDate = months[14].endDate;
          previousYearStart = months[37].startDate;
          previousYearEnd = months[26].endDate;
          displayName = this.labelTranslator('Last 13 Months');
          break;
      // case Enums.dateModes.quarterly.dateModeId:
      //   startDate = new Date(today.getFullYear(), today.getMonth() - today.getMonth() % 3, 1);
      //   endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 1);
      //   break;
      case Enums.dateModes.currentMonth.dateModeId:
      default:
        startDate = months[0].startDate;
        endDate = months[0].endDate;
        previousStartDate = months[1].startDate;
        previousEndDate = months[1].endDate;
        previousYearStart = months[12].startDate;
        previousYearEnd = months[12].endDate;
        displayName = this.labelTranslator('Current Month');
        break;
    }

    return this.buildDateFilterValue(startDate, endDate, orgDateCode, dateModeId, displayName, previousStartDate, previousEndDate, previousYearStart, previousYearEnd, null);
  }

  lookupDateMode(dateModeId: number): IDateMode {
    return Object.values(Enums.dateModes).find(dm => dm.dateModeId === dateModeId);
  }

  filterAndSortPeriods(mode: DateMonthModes, periods: DatePeriodInfoModel[]) {
    // enumerate a desc sorted set of periods to build up the periods appropriately
    const orgDateCode = dateMonthModeLookup[mode];
    const filteredSortedPeriods = periods.filter(m => m.orgDateCode === orgDateCode).sort((a, b) => new Date(b.startDate).getTime() - new Date(a.endDate).getTime());
    return filteredSortedPeriods;
  }

  buildScenarioOptions(orgDateCode: number, months: DatePeriodInfoModel[], monthsToShow: number = 12) {
    const dateModes = Enums.dateModes;
    const scenarioPastYearMode = monthsToShow  ===  13 ? dateModes.previousThirteenMonths.dateModeId : dateModes.previousTwelveMonths.dateModeId
    return [
      this.getModeDateRangeItem(orgDateCode, dateModes.currentMonth.dateModeId, months),
      this.getModeDateRangeItem(orgDateCode, dateModes.lastMonth.dateModeId, months),
      this.getModeDateRangeItem(orgDateCode, dateModes.lastThirtyDays.dateModeId, months),
      this.getModeDateRangeItem(orgDateCode, dateModes.previousThreeMonths.dateModeId, months),
      this.getModeDateRangeItem(orgDateCode, scenarioPastYearMode, months)
    ];
  }

  buildMonthOptions(months: DatePeriodInfoModel[], monthsToShow: number = 12): DateFilterValue[] {
    // local func to create a month specific date range item
    const buildMonthItem = (orgDateCode: number, buildStartDate: Date, buildEndDate: Date, previousStartDate: Date, previousEndDate: Date, previousYearStart: Date, previousYearEnd: Date) =>
    this.buildDateFilterValue(
        buildStartDate,
        buildEndDate,
        orgDateCode,
        Enums.dateModes.specificMonthOption.dateModeId,
        moment(buildStartDate).format('MMMM YYYY'),
        previousStartDate,
        previousEndDate,
        previousYearStart,
        previousYearEnd);

    return months.slice(0, monthsToShow).map((m, idx) => {
      const previousMonth = months[idx + 1];
      const previousYear = months[idx + 12];
      return buildMonthItem(m.orgDateCode, m.startDate, m.endDate, previousMonth.startDate, previousMonth.endDate, previousYear.startDate, previousYear.endDate);
    });
  }

  buildQuarterOptions(periods: DatePeriodInfoModel[], quartersToShow: number = 8): DateFilterValue[] {
    // local func to create a quarter-specific date range item
    const buildPeriodItem = (orgDateCode: number, buildStartDate: Date, buildEndDate: Date, previousStartDate: Date, previousEndDate: Date, previousYearStart: Date, previousYearEnd: Date, displayName: string) =>

      this.buildDateFilterValue(
        buildStartDate,
        buildEndDate,
        orgDateCode,
        Enums.dateModes.quarterly.dateModeId,
        displayName,
        previousStartDate,
        previousEndDate,
        previousYearStart,
        previousYearEnd);

    let quarters = periods.slice(0, quartersToShow).map((m, idx) => {
      const previousMonth = periods[idx + 1];
      const previousYear = periods[idx + 4];
      return buildPeriodItem(m.orgDateCode, m.startDate, m.endDate, previousMonth.startDate, previousMonth.endDate, previousYear.startDate, previousYear.endDate, m.periodName);
    });

    return quarters;
  }

  buildDateFilterValue(buildStartDate: Date, buildEndDate: Date, orgDateCode: number, dateModeId: number,
    displayName?: string,
    previousStartDate?: Date,
    previousEndDate?: Date,
    previousYearStart?: Date,
    previousYearEnd?: Date,
    formatExpression?: itemFormatExpression,
  ): DateFilterValue {
    const value = <DateFilterValue>{
      displayName: undefined,
      startDate: buildStartDate,
      endDate: buildEndDate,
      orgDateCode: orgDateCode,
      dateModeId: dateModeId,
      previousStartDate: previousStartDate,
      previousEndDate: previousEndDate,
      previousYearStartDate: previousYearStart,
      previousYearEndDate: previousYearEnd
    };

    value.displayName = formatExpression ? formatExpression(value) : displayName;

    return value;
  }
}
