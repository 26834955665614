<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{labels.edit}} - {{isNew ? labels.newUser : user.name}}</h4>
    </div>
    <div class="modal-body">
      <form #userDetails="ngForm" [formGroup]="userDetailsForm" autocomplete="off">
        <div class="container-fluid details-edit">
          <div class="form-group row" style="margin-bottom: unset;">
            <div class="col-md-4 col-labels">
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.email}}</label></div></div>
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.firstName}}</label></div></div>
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.lastName}}</label></div></div>
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.active}}</label></div></div>
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.role}}</label></div></div>
              <div class="row" *ngIf="roleEntityRequired()"><div class="col-md-12"><label class="field-label">{{labels.roleEntity}}</label></div></div>
              <div class="row" *ngIf="!!showUserBrand" style="height: 95px;"><div class="col-md-12"><label class="field-label">Available Hierarchies</label></div></div>
              <div class="row" *ngIf="!!localeAvailable"><div class="col-md-12"><label class="field-label">Language</label></div></div>
              <div class="row" *ngIf="!hideCurrentPassword()"><div class="col-md-12"><label class="field-label">{{labels.currentPassword}}</label></div></div>
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.newPassword}}</label></div></div>
              <div class="row"><div class="col-md-12"><label class="field-label">{{labels.confirmPassword}}</label></div></div>
            </div>
            <div class="col-md-6 col-values">
              <div class="row">
                <div class="col-md-12" [ngClass]="{ 'error': userName.invalid && userName.dirty }">
                  <input class="form-control form-control-sm" formControlName="userName" [(ngModel)]="user.userName"
                         placeholder={{labels.email}}
                         required
                         [readonly]="!isNew"
                         data-z-validate />
                  <em *ngIf="userName.invalid && (userName.dirty || userName.touched) && userName?.errors.required">{{labels.required}}</em>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <input type="text" class="form-control form-control-sm" formControlName="firstName"
                         [(ngModel)]="user.firstName" placeholder={{labels.firstName}}
                         data-z-validate />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <input type="text" class="form-control form-control-sm" formControlName="lastName"
                         [(ngModel)]="user.lastName" placeholder={{labels.lastName}}
                         data-z-validate />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="ui-checkbox">
                    <input formControlName="isUserActive" type="checkbox"
                           [(ngModel)]="user.active" />
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" [ngClass]="{ 'error': userRoles.invalid && userRoles.dirty }">
                  <select class="form-control form-controls form-control-sm" formControlName="userRoles"
                          [(ngModel)]="user.role" [compareWith]="compareRoles" (change)="roleUpdated($event)"
                          required>
                    <option [ngValue]="null">{{labels.selectARole}}</option>
                    <option *ngFor="let role of roles"
                            [ngValue]="role">
                      {{role.name}}
                    </option>
                  </select>
                  <em *ngIf="userRoles.invalid && userRoles.dirty && userRoles?.errors.required">{{labels.required}}</em>
                </div>
              </div>
              <div class="row" *ngIf="roleEntityRequired()">
                <div class="col-md-12">
                  <input type="text" class="form-control form-control-sm" formControlName="userRoleEntity"
                         [(ngModel)]="user.roleEntity" placeholder={{labels.roleEntity}}
                         data-z-validate />
                  <em *ngIf="userRoleEntity.invalid && userRoleEntity.dirty && userRoleEntity.touched && userRoleEntity?.errors.noRoleEntityMatch">
                    <span *ngIf="configService.role.isOrg3RoleId(user.role.roleId)">{{labels.roleMissingErrorOrg3}} <b>{{labels.caseSensitive}}</b></span>
                    <span *ngIf="configService.role.isOrg2RoleId(user.role.roleId)">{{labels.roleMissingErrorOrg2}} <b>{{labels.caseSensitive}}</b></span>
                    <span *ngIf="configService.role.isOrg1RoleId(user.role.roleId)">{{labels.roleMissingErrorOrg1}} <b>{{labels.caseSensitive}}</b></span>
                    <span *ngIf="configService.role.isDealerRoleId(user.role.roleId)">{{labels.roleMissingErrorDealer}}</span>
                  </em>
                  
                </div>
              </div>
              <div class="row" style="height: 95px;" *ngIf="!!showUserBrand">
                <div class="col-md-12">
                  <select multiple class="form-control form-controls form-control-sm" formControlName="userOrgLookupTypeIds"
                          [(ngModel)]="user.orgLookupTypeIds">
                    <option *ngFor="let olt of orgLookupTypes"
                            [ngValue]="olt.orgLookupTypeId"
                            data-z-validate>
                      {{olt.name}}
                    </option>
                    <em *ngIf="userOrgLookupTypeIds.invalid && userOrgLookupTypeIds.dirty && userOrgLookupTypeIds.touched && userOrgLookupTypeIds?.errors.orgLookupTypeIds">
                      <span>{{labels.userOrgMissingError}}</span>                      
                    </em>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="!!localeAvailable">
                <div class="col-md-12">
                  <select class="form-control form-controls form-control-sm" formControlName="userLocale"
                          [(ngModel)]="user.userLocale" [compareWith]="compareLocales"
                          >
                    <option [ngValue]="null">{{labels.selectALanguage}}</option>
                    <option *ngFor="let locale of locales"
                            [ngValue]="locale">
                      {{locale}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row" *ngIf="!hideCurrentPassword()">
                <div class="col-md-12" [ngClass]="{ 'error': userCurrentPassword.invalid && userCurrentPassword.dirty }" >
                  <input formControlName="userCurrentPassword" class="form-control form-control-sm"
                         name="currentPass" id="currentPass"
                         [(ngModel)]="currentPassword" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" [ngClass]="{ 'error': userNewPassword.invalid && userNewPassword.dirty }">
                  <input type="password" class="form-control form-control-sm" formControlName="userNewPassword"
                         [(ngModel)]="password"
                         [required]="isNew" />
                  <em *ngIf="userNewPassword.invalid && (userNewPassword.dirty ||userNewPassword.touched) && userNewPassword?.errors.minlength">{{labels.passwordLengthWarning}}</em>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" [ngClass]="{ 'error': userConfirmNewPassword.invalid && userConfirmNewPassword.dirty }">
                  <input type="password" class="form-control form-control-sm" formControlName="userConfirmNewPassword"
                         [(ngModel)]="confirmPassword"
                         [required]="userNewPassword.dirty" />
                  <em *ngIf="userConfirmNewPassword.invalid && userConfirmNewPassword.touched && userConfirmNewPassword?.errors.minlength">{{labels.passwordLengthWarning}}</em>
                  <em *ngIf="userConfirmNewPassword.invalid && (userConfirmNewPassword.dirty || userConfirmNewPassword.touched) && userConfirmNewPassword?.errors.unmatchedpassword">{{labels.passwordMatchWarning}}</em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-w-md btn-gap btn-primary btn-sm" (click)="save()" [disabled]="disableSave">{{labels.save}}</button>
      <button type="button" class="btn btn-w-md btn-gap btn-primary btn-sm" (click)="cancel()">{{labels.cancel}}</button>
    </div>
  </div>
</div>
