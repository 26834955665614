// @angular/*
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

// Services
import { AuthGuard } from './_shared/routeGuards/authGuard';
import { LeadsOverviewGuard } from './_shared/routeGuards/leadsOverviewGuard';
import { ModalService } from './_shared/services/modal/modal.service';

import { AuthenticationInterceptorService } from './_shared/services/authentication/interceptors/authenticationInterceptor.service';
import * as Services from './_shared/services/services-index';
import * as APIService from './_shared/services/api/api-index';
import { FilterBarService } from './_shared/filter/filter-bar.service';
import { FilterDateService } from './_shared/filter/filter-date.service';

// Core modules
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import * as moment from 'moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule } from 'angular2-toaster';
import { LocalStorageModule } from 'angular-2-local-storage';
// import { ClickOutsideModule } from 'ng-click-outside';
import { AppComponent } from './app.component';

// environment
import { environment } from '../environments/environment';

// ngrx
import { StoreModule, MetaReducer, META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './_store/app.effects';
import { StoreRouterConnectingModule, routerReducer, MinimalRouterStateSerializer } from '@ngrx/router-store';
import { authReducer, configReducer, localeReducer, logger } from './_store/app.reducer';
import * as fromFilter from './_shared/filter/store';
import { ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY } from './_store/app.tokens';

// shared module - loads of treats
import { SharedModule } from './_shared/shared.module';

import { SetAppInjector } from './app.injector';
import { AppRouter } from './app.routes';
import { HttpErrorInterceptor } from './http-error.interceptor';

// Layout
import * as Layout from './_layout/layout-index';

// Modals
import * as Modals from './_shared/modals/modals-index';

// Pages
import { ExceptionComponent } from './errors/exception.component';
import { LoginComponent } from './login/login.component';
import { SsoLoginComponent } from './login/ssologin.component';
import { SsoLoginV2Component } from './login/ssologinV2.component';
import { SloLogoutComponent } from './logout/slologout.component';
import { NotFoundComponent } from './errors/404.component';

import { BaseComponent } from './_shared/components/base.component';

// Reports & Dashboards
import { FilterBreadcrumbsComponent } from './_shared/filterBreadcrumbs/filterBreadcrumbs.component';
import { FilterSelectAllComponent } from './_shared/filter/filterSelectAll.component';

// Global Pipes
import { TranslateLayoutPipe } from './_shared/pipes/translate-layout.pipe';

// Genius template
import { SIDEBAR_TOGGLE_DIRECTIVES } from './_shared/utilities/sidebar.directive';

// Directives
import * as Directives from './_shared/directives/directives-index';
import { storageMetaReducer } from './_store/storage.metareducer';
import { LogoutComponent } from './logout/logout.component';

export function breadcrumbServiceFactory(router: Router) {
  return new Services.BreadcrumbService(router);
}

// factory meta-reducer configuration function
export function getAppStorageMetaReducer(saveKeys: string[],
  localStorageKey: string,
  storageService: Services.LocalStorageService
  ): MetaReducer<any> {
    return storageMetaReducer(saveKeys, localStorageKey, storageService);
}

@NgModule({
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ToasterModule.forRoot(),
    AppRouter,
    SharedModule,
    StoreModule.forRoot({ auth: authReducer, config: configReducer, filters: fromFilter.filterReducer, locale: localeReducer, router: routerReducer }, {
      metaReducers: [logger],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    // StoreModule.forFeature('filters', fromFilter.filterReducer),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppEffects, fromFilter.FilterEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: MinimalRouterStateSerializer
    }),
  ],
  declarations: [
    AppComponent,
    // MenuItemComponent,
    Directives.NavDropdownDirective,
    Directives.NavDropdownToggleDirective,
    Directives.ItemSelectedToggleDirective,

    // layout
    Layout.LayoutComponent,
    Layout.BreadcrumbComponent,
    Layout.LayoutFooterComponent,
    Layout.LayoutSidebarComponent,
    // Layout.LayoutSecondaryNavComponent,
    Layout.LayoutNavbarComponent,
    Layout.LanguageSelectorModalComponent,
    Layout.NavbarUserComponent,
    Layout.NavbarNotificationsComponent,
    Layout.NavbarLogoutComponent,
    Layout.SdDateRangePickerComponent,
    Layout.SdMetricSelectorComponent,
    Layout.SupportModalComponent,
    Layout.SpinnerComponent,

    // Modals
    Modals.GenericConfirmModalComponent,
    Modals.GenericModalComponent,
    Modals.SharedHelpTextModalComponent,

    // Translations
    TranslateLayoutPipe,
    // pages
    ExceptionComponent,
    NotFoundComponent,
    LoginComponent,
    LogoutComponent,
    SsoLoginComponent,
    SsoLoginV2Component,
    SloLogoutComponent,

    BaseComponent,

    // Filter/ Breadcrumbs
    FilterSelectAllComponent,
    FilterBreadcrumbsComponent,

    // template
    SIDEBAR_TOGGLE_DIRECTIVES
  ],
  entryComponents: [
    Modals.GenericConfirmModalComponent,
    Modals.GenericModalComponent,
    Modals.SharedHelpTextModalComponent,
    Layout.SupportModalComponent,
  ],
  providers: [
    HttpClient,
    ModalService,
    Services.AuthenticationService,
    Services.BillboardService,
    Services.ConfigurationService,
    Services.DataTableService,
    Services.LocalCacheService,
    Services.AppInsightsService,
    Services.LoggerService,
    Services.FilterService,
    Services.SpinnerService,
    Services.UtilsService,
    Services.ExportsService,
    Services.SdDateRangePickerService,
    Services.SdMetricSelectorService,
    Services.OrgLookupService,
    Services.LocaleService,
    Services.LayoutTranslationService,
    Services.ResourceService,
    // API Services
    APIService.ContentService,
    APIService.DashboardService,
    APIService.DealerPerformanceReportService,
    APIService.DigitalAdvertisingChannelSummaryService,
    APIService.DigitalAdvertisingProviderSummaryService,
    APIService.DigitalAdvertisingSummaryService,
    APIService.DigitalAdvertisingOverviewService,
    APIService.DigitalAdvertisingScorecardService,
    APIService.FacebookSummaryService,
    APIService.InventoryOverviewService,
    APIService.LeadTransactionService,
    APIService.ReportsService,
    APIService.SalesContributionService,
    APIService.SalesOverviewService,
    APIService.SummaryReportService,
    APIService.UsersService,
    // jmstodo: even though these aren't actually productized, do a little work so we can delete these services at least
    APIService.WebsiteAfterSalesService,
    APIService.WebsiteLandingPageService,
    // Translations
    TranslateLayoutPipe,
    // Helper Services
    Layout.LayoutHelperService,
    FilterBarService,
    FilterDateService,
    LeadsOverviewGuard,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true
    },
    {

      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: Services.BreadcrumbService,
      useFactory: breadcrumbServiceFactory,
      deps: [Router]
    },
    {
      provide: 'moment', useFactory: (): any => moment
    },
    { provide: 'Window', useValue: window },
    { provide: ROOT_STORAGE_KEYS, useValue: ['filters', 'locale']},
    { provide: ROOT_LOCAL_STORAGE_KEY, useValue: '__app_storage__'},
    {
      provide   : META_REDUCERS,
      deps      : [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, Services.LocalStorageService],
      useFactory: getAppStorageMetaReducer,
      multi: true
    },
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    SetAppInjector(injector);
  }
}
