import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toprettytimestring' })
export class TimeStringPipe implements PipeTransform {
  transform(time: number, unitOfTime: string): string {

    let timeInMinutes = 0;

    if (unitOfTime === 'minutes') {
      timeInMinutes = time;
    } else {
      timeInMinutes = time / 60;
    }


    if (timeInMinutes >= 60) {
      let hours = 0;
      let minutes = 0;
      hours = Math.floor(timeInMinutes / 60);
      minutes = Math.floor(timeInMinutes % 60);

      if (hours === 0) {
        return minutes.toString() + 'm';
      } else if (minutes === 0) {
        return hours.toString() + 'h';
      } else {
        return hours.toString() + 'h ' + minutes.toString() + 'm';
      }
    } else if (timeInMinutes < 60) {
      let minutes = 0;
      let seconds = 0;
      minutes = Math.floor(timeInMinutes);
      seconds = Math.floor(60 * ((timeInMinutes) - (minutes)))

      if (minutes === 0) {
        return seconds.toString() + 's';
      } else if (seconds === 0) {
        return minutes.toString() + 'm';
      } else {
        return minutes.toString() + 'm ' + seconds.toString() + 's';
      }
    } else {
        return 'N/A';
    }
  }
}
