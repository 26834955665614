import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, IPropertyOverride, INameValuePair } from '../../../../_shared/models/models-index';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {
  public metricDisplayModes = [];
  public graphConfig: IDualAxisLineGraphConfig = null;
  public useDefaultComparisonColumns: true;
  public dealerLevelMetricOverrides?: IPropertyOverride[];
  public summaryTableColumns: IDefaultTableColumnConfig[] = [];
  public trendTableMetrics: INameValuePair[] = [];
}
