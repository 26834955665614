// import { HttpClient } from '@angular/common/http';
import {HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../services-index';

@Injectable({ providedIn: 'root'})
export class ManagementService {

    headers = {'Content-Type': 'application/json'};

    constructor(
        private http: HttpClient,
        private appInsights: AppInsightsService) { }

    getSalesCalendar(reportRequest: any, reportType?: string): Observable<Models.SalesCalendarResponseModel> {
        reportRequest.reportType = reportType || reportRequest.reportType;

        return this.http.post<Models.SalesCalendarResponseModel>(
            environment.baseApiUri + '/getSalesCalendar',
            JSON.stringify(reportRequest),
            { headers: this.headers })
            .pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    addSalesMonth(salesMonth: Models.SalesCalendar): Observable<any> {
        return this.http.post(environment.baseApiUri + '/addSalesMonth', salesMonth)
        .pipe(
            catchError(err => {
                appInsights.trackException(err);
                return  this.handleError(err);
            })
        );
    }

    getSalesTargets(reportRequest: any, reportType?: string): Observable<Models.SalesTargetResponseModel> {
        reportRequest.reportType = reportType || reportRequest.reportType;

        return this.http.post<Models.SalesTargetResponseModel>(
            environment.baseApiUri + '/getSalesTargets',
            JSON.stringify(reportRequest),
            { headers: this.headers })
            .pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    addSalesTarget(salesTarget: Models.SalesTarget): Observable<any> {
        return this.http.post(environment.baseApiUri + '/addSalesTarget', salesTarget)
        .pipe(
            catchError(err => {
                appInsights.trackException(err);
                return  this.handleError(err);
            })
        );
    }
    uploadFile(file: File, userId: number, report: string): Observable<HttpEvent<any>> {

        const formData = new FormData();
        formData.append('upload', file);
        const params = new HttpParams();

        const options = {
          params: params,
          reportProgress: false,
        };

        const queryParamString = `?fileName=${file.name}&&userId=${userId}&&report=${report}`;
        return this.http.post<any>(
            environment.baseApiUri + '/uploadFile' + queryParamString, file, options,
            )
            .pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
      }


    getReportingVehicles(reportRequest: any, reportType?: string): Observable<Models.ReportingVehicleResponseModel> {
        // reportRequest.reportType = reportType || reportRequest.reportType;

        return this.http.post<Models.ReportingVehicleResponseModel>(
            environment.baseApiUri + '/getReportingVehicles',
            JSON.stringify(reportRequest),
            { headers: this.headers })
            .pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    addReportingVehicle(reportingVehicle: Models.ReportingVehicle): Observable<any> {
        return this.http.post(environment.baseApiUri + '/addReportingVehicle', reportingVehicle)
        .pipe(
            catchError(err => {
                appInsights.trackException(err);
                return  this.handleError(err);
            })
        );
    }

    getUserByUserId(reportRequest: any, reportType?: string): Observable<Models.User> {
        reportRequest.reportType = reportType || reportRequest.reportType;
        return this.http.post<Models.User>(environment.baseApiUri + '/getUserById', JSON.stringify(reportRequest), { headers: this.headers })
            .pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return this.handleError(err);
                })
        );
    }

    getUserByUserName(request: string): Observable<Models.User> {
        const requestModel: Models.UserByNameRequestModel = {
            userName: request
        };

        return this.http.post<Models.User>(environment.baseApiUri + '/getUserByName', requestModel, { headers: this.headers })
            .pipe(
                catchError(err => {
                    appInsights.trackException(err);
                    return  this.handleError(err);
                })
            );
    }

    getRoles(): Observable<Models.Role[]> {
        return this.http.get<Models.Role[]>(environment.baseApiUri + '/getRoles', { headers: this.headers })
        .pipe(
            catchError(err => {
                appInsights.trackException(err);
                return  this.handleError(err);
            })
        );
    }

    private handleError(err: any) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        }
        this.appInsights.trackException(err);
        console.error(err);
        return throwError(errorMessage);
    }
}
