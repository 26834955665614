import { Injectable } from '@angular/core';
import { RoutingConfig } from '../../../../_config/routing/routing.config';
import { IRoutingConfig, IRouteConfig } from '../../../models/models-index';

@Injectable()
export class RoutingConfigService {

    routingConfig: IRoutingConfig = new RoutingConfig;

    get defaultRoute(): string {
        return this.getDefaultRoute();
    }

    constructor() { }

    private getDefaultRoute(): string {

        const defaultRoute = this.routingConfig.routes.find(r => r.default === true);

        if (!!defaultRoute) {
            return defaultRoute.defaultRoute;
        }

        // System default
        return '/leads/overview';
    }
}
