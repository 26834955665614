import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ContentService } from '../../_shared/services/api/api-index';
import { HtmlContentModel } from '../../_shared/models/models-index';
import { filter, map, switchMap } from 'rxjs/operators';
import { LocaleService } from '../../_shared/locale/locale.service';

@Component({
  selector: 'navbar-support',
  templateUrl: './support.component.html'
})

export class SupportModalComponent {
  locale: string;
  supportContent$: Observable<string>;
  constructor(
    public dialogRef: MatDialogRef<SupportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contentService: ContentService,
    private localeService: LocaleService,
  ) {
      this.supportContent$ = this.localeService.locale$.pipe(
          filter(loc => !!loc),
          switchMap(locale => {
            this.locale = locale;
            return this.contentService.getContent('Support', locale).pipe(map(c => (c as HtmlContentModel).html));
        }));
  }


  closeModal() {
    this.dialogRef.close();
  }

}
