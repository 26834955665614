import { IDefaultTableColumnConfig, ISourceSummaryConfig, IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';

export class SourceSummaryConfig implements ISourceSummaryConfig {

    public reportTitle = '';

    public metricDisplayModes = [];

    public useDefaultComparisonColumns: true;

    public hasTrendTable: true;

    public graphConfig: IDualAxisLineGraphConfig = null;

    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}