import { IDefaultTableColumnConfig, ILeadsVehicleConfig, IMultiLevelDualAxisLineGraphConfig, IPropertyOverride } from '../../../../_shared/models/models-index';

export class LeadsVehicleConfig implements ILeadsVehicleConfig {
    public reportTitle?: string;
    public metricDisplayModes: [];
    public useDefaultComparisonColumns: true;
    public dealerLevelMetricOverrides: IPropertyOverride[]
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];

}