import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 import { SdFilterPanelMaterialModule } from './material.module';
import { SdFilterPanelListComponent } from './components/sd-filter-panel-list.component';
import { SdFilterPanelHostComponent } from './components/sd-filter-panel-host.component';
import { SdFilterPanelCollectionComponent } from './components/sd-filter-panel-collection.component';
import { FormsModule } from '@angular/forms';
import { SdFilterPanelValueService } from './services/sd-filter-panel-value.service';
import { SdFilterPanel } from './types/sd-filter-panel';


@NgModule({
	declarations: [
		SdFilterPanelHostComponent,
		SdFilterPanelListComponent,
		SdFilterPanelCollectionComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SdFilterPanelMaterialModule
	],
	exports: [

	],
	providers: [
		SdFilterPanelValueService,
		SdFilterPanel
	],
	entryComponents: [
		SdFilterPanelListComponent,
		SdFilterPanelCollectionComponent,
		SdFilterPanelHostComponent
	]
})
export class SdFilterPanelModule { }
