import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService, ConfigurationService } from '../../_shared/services/services-index';
import { IAuthenticationInfo, IOrgLookup } from '../../_shared/models/models-index';
import { Enums } from '../../_shared/enums/enums';
import { ReportsService } from '../../_shared/services/api/api-index';
import { Store } from '@ngrx/store';
import { AppState } from '../../_store/app-state.model';
import { selectLoggedInUser } from '../../_store/app.selectors';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';


@Injectable()
export class LeadsOverviewGuard implements CanActivate {

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private configService: ConfigurationService,
        private reportsService: ReportsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const orgLookups$ = this.reportsService.orgLookups$;
        const user$ = this.store.select(selectLoggedInUser);

        return combineLatest(orgLookups$, user$).pipe(
            map(([orgLookups, user]) => {
                // Get the url that the user is trying to access
                const url = state.url;

                if (url.includes('overview/national')) {
                    if (this.configService.role.roleHasCorporateAccess(user.role)) {
                        return true;
                    }

                    return this.redirectToProperOverview(user);
                } else if (url.includes('overview/orgcode3')) {
                    if (this.configService.role.roleHasOrg3Access(user.role)) {
                        // If the user is an orgcode2 user...
                        if (this.configService.role.isOrg3Role(user.role)) {
                            // ... then their roleEntity should match the route :orgcode1 to have access
                            if (route.params['orgcode3'] === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        // return true for role levels above orgcode2
                        return true;
                    }

                    return this.redirectToProperOverview(user);
                } else if (url.includes('overview/orgcode2')) {
                    if (this.configService.role.roleHasOrg2Access(user.role)) {
                        // If the user is an orgcode2 user...
                        if (this.configService.role.isOrg2Role(user.role)) {
                            // ... then their roleEntity should match the route :orgcode1 to have access
                            if (route.params['orgcode2'] === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        // return true for role levels above orgcode2
                        return true;
                    }

                    return this.redirectToProperOverview(user);
                } else if (url.includes('overview/orgcode1')) {
                    if (this.configService.role.roleHasOrg1Access(user.role)) {
                        // If the user is an orgcode1 user...
                        if (this.configService.role.isOrg1Role(user.role)) {
                            // ... then their roleEntity should match the route :orgcode1 to have access
                            if (route.params['orgcode1'] === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        // If the user is an orgcode2 user...
                        if (this.configService.role.isOrg2Role(user.role)) {
                            // ... then the district needs to be in their org
                            const [orgLookup] = orgLookups.filter(ol => ol.orgCode2 === user.roleEntity && ol.orgCode1 === route.params['orgcode1']);
                            if (orgLookup && orgLookup.orgCode2 === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        // return true for role levels above orgcode2
                        return true;
                    }

                    return this.redirectToProperOverview(user);
                } else {
                    if (this.configService.role.roleHasDealerAccess(user.role)) {
                        // If the user is a dealer user...
                        if (this.configService.role.isDealerRole(user.role)) {
                            // ... then their roleEntity should match the route :dealer to have access
                            if (route.params['dealer'] === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        const [orgLookup] = orgLookups.filter(ol => ol.dealerCode === route.params['dealer']);

                        // If the user is an orgcode1 user...
                        if (this.configService.role.isOrg1Role(user.role)) {
                            // ... then the dealer needs to be in their org
                            if (orgLookup && orgLookup.orgCode1 === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        // If the user is an orgcode2 user...
                        if (this.configService.role.isOrg2Role(user.role)) {
                            // ... then the dealer needs to be in their org
                            if (orgLookup && orgLookup.orgCode2 === user.roleEntity) {
                                return true;
                            }

                            return this.redirectToProperOverview(user);
                        }

                        // return true for role levels above orgcode2
                        return true;
                    }

                    return this.redirectToProperOverview(user);
                }
            }));
    }

    // Redirect to the correct overview level for the user and
    // return false (indicating that the user can't access the attempted route)
    redirectToProperOverview(authInfo: IAuthenticationInfo): boolean {
        let leadsOverviewRedirect = '';

        if (this.configService.role.isDealerRole(authInfo.role)) {
          leadsOverviewRedirect = `overview/dealer/${authInfo.roleEntity}`;
        }
        else if (this.configService.role.isOrg1Role(authInfo.role)) {
          leadsOverviewRedirect = `overview/orgcode1/${authInfo.roleEntity}`;
        }
        else if (this.configService.role.isOrg2Role(authInfo.role)) {
          leadsOverviewRedirect = `overview/orgcode2/${authInfo.roleEntity}`;
        }
        else if (this.configService.role.isOrg3Role(authInfo.role)) {
          leadsOverviewRedirect = `overview/orgcode3/${authInfo.roleEntity}`;
        }
        else {
          leadsOverviewRedirect = `overview/national`;
        }

        this.router.navigateByUrl(`leads/${leadsOverviewRedirect}`);
        return false;
    }

}
