import {
    IDefaultTableColumnConfig,
    IDigAdChannelConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';

export class DigAdChannelConfig implements IDigAdChannelConfig {
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
