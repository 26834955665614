import {createAction, props} from '@ngrx/store';
import * as Models from '../_shared/models/models-index';
import { HttpErrorResponse } from '@angular/common/http';
import { LocaleState } from './app-state.model';
import { TradeInMakeModelSheetComponent } from '../provider-tools/provider-tools-index';

export const login = createAction(
    '[Login Page] User Login',
    props<{auth: Models.IAuthenticationInfo}>()
);

export const loginSuccess = createAction(
  '[App Effects] Login',
  props<{
    auth: Models.IAuthenticationInfo,
    config: Models.IAppConfig
  }>()
);

export const logout = createAction(
  '[Top Menu] Logout'
);

export const resetAuth = createAction(
  '[App Component] OnInit'
);

export const initializeLocale = createAction(
  '[App Component OnInit] Initialize Locale',
  props<{
    locale: string,
    userDefaultLocale?: string
  }>()
);

export const initializeCachedUser = createAction(
  '[App Component OnInit] Initialize Cached User',
  props<{
    user: Models.IAuthenticationInfo
  }>()
);

export const loadConfig = createAction(
  '[App Component OnInit] Load Config'
);


export const loadConfigSuccess = createAction(
  '[App Component GetConfiguration] Load Config Success',
  props<{
    currentSalesMonth: Date,
    chatProviders: Models.IProviderLookup[],
    dayOfSalesMonth: number,
    dealerLookups: Models.IDealerLookup[],
    deviceTypes: Models.IDeviceType[],
    digAdProviders: Models.IProviderLookup[],
    leadTypes: Models.ILeadType[],
    lmaLookups: Models.IOrgLookup[],
    profitCenters: Models.IProfitCenter[],
    digAdPrograms: Models.IDigAdProgram[],
    virtual20Lookups: Models.IOrgLookup[],
    vehicleMakes: Models.IVehicleMake[],
    vehicleClasses: string[],
    vehicleModels: Models.IVehicleModel[],
    vehicleLookups: Models.IVehicleLookup[],
    orgLookups: Models.IOrgLookup[],
    saleTypes: Models.ISaleType[],
    sourceTypes: Models.ISourceType[],
    socialMediaProviders: Models.IProviderLookup[]
    reputationProviders: Models.IProviderLookup[]
    websiteProviders: Models.IProviderLookup[]
  }>()
);

export const loadLocale = createAction(
  '[App Component OnInit] Load Locale'
);

export const loadLocaleSuccess = createAction(
  '[App Component OnInit] Load Locale Success',
  props<{
    locale: string,
    userDefaultLocale?: string}>()
);

export const updateLocale = createAction(
  '[Locale Service] Update Locale',
  props<{
    locale: string,
    userDefaultLocale?: string
  }>()
);

export const updateLocaleSuccess = createAction(
  '[Locale Service] Update Locale Success',
  props<{
    locale: string,
    userDefaultLocale?: string}>()
);

export const loadConfigError = createAction(
  '[App Component GetConfiguration] Load Config Error',
  props<{error: string | Error | HttpErrorResponse}>()
);
