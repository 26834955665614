import { Injectable } from '@angular/core';
import { LocalStorageService, ILocalStorageServiceConfig } from 'angular-2-local-storage';
import { AuthenticationService } from '../authentication/authentication.service';
import { Enums, IEnums } from './../../enums/enums';

interface ILocalCacheService {
    retrieveCache<T>(storageKey: string): T;
    setCache<T>(storageKey: string, cacheData: T): boolean;
    purgeCache(storageKey: string): void;
    purgeAllCaches(): void;
}

@Injectable()
export class LocalCacheService implements ILocalCacheService {
    private enums: IEnums = Enums;
    private localStorage: LocalStorageService;
    private localStorageConfig: ILocalStorageServiceConfig = {
        prefix: 'sd',
        storageType: 'localStorage'
    };

    constructor() {
        this.localStorage = new LocalStorageService(this.localStorageConfig);
    }

    retrieveCache<T>(storageKey: string): T {
        return <T>this.localStorage.get(storageKey);
    }

    setCache<T>(storageKey: string, cacheData: T): boolean {
        return this.localStorage.set(storageKey, cacheData);
    }

    purgeCache(storageKey: string): void {
        this.localStorage.remove(storageKey);
    }

    purgeAllCaches(): void {
        this.localStorage.remove('__app_storage__');
        this.localStorage.remove(this.enums.cacheKeys.authData);
        this.localStorage.remove(this.enums.cacheKeys.rememberMe);
        this.localStorage.remove(this.enums.cacheKeys.currentFilter);
        this.localStorage.remove(this.enums.cacheKeys.defaultFilter);
        this.localStorage.remove(this.enums.cacheKeys.orgLookups);
        this.localStorage.remove(this.enums.cacheKeys.salesUnit);
    }
}
