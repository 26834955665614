import {
  IDefaultTableColumnConfig,
  IDigitalRetailingOverviewBillboardConfig,
  IDigitalRetailingOverviewConfig,
  IDualAxisLineGraphConfig,

  IMultiLevelDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class DigitalRetailingOverviewConfig implements IDigitalRetailingOverviewConfig {
  public reportTitle = 'Digital Retailing Overview (BETA)';
  public useDefaultComparisonColumns = false;
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];
  public dealerTableLabel = 'Dealer Summary';
  public showEngagementAnalysis = true;
  public chartSeriesColors = ['#000', '#ed1c24', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public billboards: IDigitalRetailingOverviewBillboardConfig[] = [{
    title: 'Session Start Rate',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'sessionStartRate',
    metricMOMPropertyName: 'sessionStartRateMOM',
    metricYOYPropertyName: 'sessionStartRateYOY',
    metricPreviousMonthPropertyName: 'sessionStartRatePreviousMonth',
    metricPreviousMTDPropertyName: 'sessionStartRatePreviousMTD',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: false,
    trendArrowDrivingPropertyName: 'sessionStartRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewSessionStartRateBillboardHelp',
    helpTextTitle: 'Session Start Rate',
  }, {
    title: 'Payment Calc Rate',
    subtitle: null,
    iconClass: 'fa-calculator fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'paymentCalcRate',
    metricMOMPropertyName: 'paymentCalcRateMOM',
    metricYOYPropertyName: 'paymentCalcRateYOY',
    metricPreviousMonthPropertyName: 'paymentCalcRatePreviousMonth',
    metricPreviousMTDPropertyName: 'paymentCalcRatePreviousMTD',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: false,
    trendArrowDrivingPropertyName: 'paymentCalcRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewPaymentCalcRateBillboardHelp',
    helpTextTitle: 'Payment Calc Rate',
  }, {
    title: 'Lead Capture Rate',
    subtitle: null,
    iconClass: 'fa-user-circle fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'leadCaptureRate',
    metricMOMPropertyName: 'leadCaptureRateMOM',
    metricYOYPropertyName: 'leadCaptureRateYOY',
    metricPreviousMonthPropertyName: 'leadCaptureRatePreviousMonth',
    metricPreviousMTDPropertyName: 'leadCaptureRatePreviousMTD',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: false,
    trendArrowDrivingPropertyName: 'leadCaptureRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewLeadCaptureRateBillboardHelp',
    helpTextTitle: 'Lead Capture Rate',
  }, {
    title: 'Appointment Rate',
    subtitle: null,
    iconClass: 'fa-check-square-o fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'appointmentRate',
    metricMOMPropertyName: 'appointmentRateMOM',
    metricYOYPropertyName: 'appointmentRateYOY',
    metricPreviousMonthPropertyName: 'appointmentRatePreviousMonth',
    metricPreviousMTDPropertyName: 'appointmentRatePreviousMTD',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: false,
    trendArrowDrivingPropertyName: 'appointmentRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewAppointmentRateBillboardHelp',
    helpTextTitle: 'Appointment Rate',
  }];
  public kpiSheetOptions = {
    overview: [{
      name: 'providerId',
      label: 'Provider',
      isHeader: true
    }, {
      name: 'uniqueShoppers',
      label: 'Unique Shoppers',
    }, {
      name: 'leadCaptureRate',
      label: 'Lead Capture Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'paymentCalcRate',
      label: 'Payment Calc Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'tradeInRate',
      label: 'Trade-In Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'allCreditAppRate',
      label: 'All Credit App Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'appointmentRate',
      label: 'Appointment Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }]
  };
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.digitalRetailingMetrics.sessionStarts.metricId,
        displayName: Enums.digitalRetailingMetrics.sessionStarts.name,
        propertyName: Enums.digitalRetailingMetrics.sessionStarts.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.sessionStartRate.metricId,
        displayName: Enums.digitalRetailingMetrics.sessionStartRate.name,
        propertyName: Enums.digitalRetailingMetrics.sessionStartRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.uniqueShoppers.metricId,
        displayName: Enums.digitalRetailingMetrics.uniqueShoppers.name,
        propertyName: Enums.digitalRetailingMetrics.uniqueShoppers.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.visits.metricId,
        displayName: Enums.digitalRetailingMetrics.visits.name,
        propertyName: Enums.digitalRetailingMetrics.visits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.visitors.metricId,
        displayName: Enums.digitalRetailingMetrics.visitors.name,
        propertyName: Enums.digitalRetailingMetrics.visitors.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.paymentCalculators.metricId,
        displayName: Enums.digitalRetailingMetrics.paymentCalculators.name,
        propertyName: Enums.digitalRetailingMetrics.paymentCalculators.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.paymentCalculatorRate.metricId,
        displayName: Enums.digitalRetailingMetrics.paymentCalculatorRate.name,
        propertyName: Enums.digitalRetailingMetrics.paymentCalculatorRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.tradeIns.metricId,
        displayName: Enums.digitalRetailingMetrics.tradeIns.name,
        propertyName: Enums.digitalRetailingMetrics.tradeIns.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.tradeInRate.metricId,
        displayName: Enums.digitalRetailingMetrics.tradeInRate.name,
        propertyName: Enums.digitalRetailingMetrics.tradeInRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.financeFinishes.metricId,
        displayName: Enums.digitalRetailingMetrics.financeFinishes.name,
        propertyName: Enums.digitalRetailingMetrics.financeFinishes.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.financeFinishRate.metricId,
        displayName: Enums.digitalRetailingMetrics.financeFinishRate.name,
        propertyName: Enums.digitalRetailingMetrics.financeFinishRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.leadCaptures.metricId,
        displayName: Enums.digitalRetailingMetrics.leadCaptures.name,
        propertyName: Enums.digitalRetailingMetrics.leadCaptures.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.leadCaptureRate.metricId,
        displayName: Enums.digitalRetailingMetrics.leadCaptureRate.name,
        propertyName: Enums.digitalRetailingMetrics.leadCaptureRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.allCreditApps.metricId,
        displayName: Enums.digitalRetailingMetrics.allCreditApps.name,
        propertyName: Enums.digitalRetailingMetrics.allCreditApps.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.allCreditAppRate.metricId,
        displayName: Enums.digitalRetailingMetrics.allCreditAppRate.name,
        propertyName: Enums.digitalRetailingMetrics.allCreditAppRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.preQualifieds.metricId,
        displayName: Enums.digitalRetailingMetrics.preQualifieds.name,
        propertyName: Enums.digitalRetailingMetrics.preQualifieds.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.preQualifiedRate.metricId,
        displayName: Enums.digitalRetailingMetrics.preQualifiedRate.name,
        propertyName: Enums.digitalRetailingMetrics.preQualifiedRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.creditApps.metricId,
        displayName: Enums.digitalRetailingMetrics.creditApps.name,
        propertyName: Enums.digitalRetailingMetrics.creditApps.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalRetailingMetrics.creditAppRate.metricId,
        displayName: Enums.digitalRetailingMetrics.creditAppRate.name,
        propertyName: Enums.digitalRetailingMetrics.creditAppRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.appointments.metricId,
        displayName: Enums.digitalRetailingMetrics.appointments.name,
        propertyName: Enums.digitalRetailingMetrics.appointments.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      }, {
        id: Enums.digitalRetailingMetrics.appointmentRate.metricId,
        displayName: Enums.digitalRetailingMetrics.appointmentRate.name,
        propertyName: Enums.digitalRetailingMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ], childMetrics: [
      {
        id: Enums.chatProviders.allProviders.providerId,
        displayName: Enums.chatProviders.allProviders.displayName,
        propertyName: Enums.chatProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      // Add providers here later
    ],
    defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
    defaultParentIds: [Enums.digitalRetailingMetrics.sessionStarts.metricId, Enums.digitalRetailingMetrics.appointmentRate.metricId],
    metricOneColorHexCode: '#000',
    metricTwoColorHexCode: '#ed1c24'
  };
  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
      // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
    }, {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },

    {
      show: true,
      header: 'Website Visits',
      columnDef: 'visits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Website Visits Prev. Month',
      columnDef: 'visitsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Website Visits MOM',
      columnDef: 'visitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Website Visitors',
      columnDef: 'visitors',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Website Visitors Prev. Month',
      columnDef: 'visitorsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Website Visitors MOM',
      columnDef: 'visitorsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Session Starts',
      columnDef: 'sessionStarts',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Session Starts Prev. Month',
      columnDef: 'sessionStartsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Session Starts MOM',
      columnDef: 'sessionStartsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Unique Shoppers',
      columnDef: 'uniqueShoppers',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Unique Shoppers Prev. Month',
      columnDef: 'uniqueShoppersPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Unique Shoppers MOM',
      columnDef: 'uniqueShoppersMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Session Start Rate',
      columnDef: 'sessionStartRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Session Start Rate Prev. Month',
      columnDef: 'sessionStartRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Session Start Rate MOM',
      columnDef: 'sessionStartRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Payment Calculators',
      columnDef: 'paymentCalcs',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Payment Calculators Prev. Month',
      columnDef: 'paymentCalcsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Payment Calculators MOM',
      columnDef: 'paymentCalcsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Payment Calculator Rate',
      columnDef: 'paymentCalcRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Payment Calculator Rate Prev. Month',
      columnDef: 'paymentCalcRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Payment Calculator Rate MOM',
      columnDef: 'paymentCalcRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Trade-Ins',
      columnDef: 'tradeIns',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-Ins Prev. Month',
      columnDef: 'tradeInsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-Ins MOM',
      columnDef: 'tradeInsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Trade-In Rate',
      columnDef: 'tradeInRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Rate Prev. Month',
      columnDef: 'tradeInRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Rate MOM',
      columnDef: 'tradeInRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'F & I Completes',
      columnDef: 'financeFinishes',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'F & I Completes Prev. Month',
      columnDef: 'financeFinishesPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'F & I Completes MOM',
      columnDef: 'financeFinishesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'F & I Complete Rate',
      columnDef: 'financeFinishRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'F & I Complete Rate Prev. Month',
      columnDef: 'financeFinishRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'F & I Complete Rate MOM',
      columnDef: 'financeFinishRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Lead Captures',
      columnDef: 'leadCaptures',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Lead Captures Prev. Month',
      columnDef: 'leadCapturesPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Lead Captures MOM',
      columnDef: 'leadCapturesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Lead Capture Rate',
      columnDef: 'leadCaptureRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Lead Capture Rate Prev. Month',
      columnDef: 'leadCaptureRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Lead Capture Rate MOM',
      columnDef: 'leadCaptureRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'All Credit Apps',
      columnDef: 'allCreditApps',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'All Credit Apps Prev. Month',
      columnDef: 'allCreditAppsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'All Credit Apps MOM',
      columnDef: 'allCreditAppsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'All Credit Apps Rate',
      columnDef: 'allCreditAppRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'All Credit Apps Rate Prev. Month',
      columnDef: 'allCreditAppRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'All Credit Apps Rate MOM',
      columnDef: 'allCreditAppRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Pre-Qualified',
      columnDef: 'preQualified',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Pre-Qualified Prev. Month',
      columnDef: 'preQualifiedPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Pre-Qualified MOM',
      columnDef: 'preQualifiedMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Pre-Qualified Rate',
      columnDef: 'preQualifiedRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Pre-Qualified Rate Prev. Month',
      columnDef: 'preQualifiedRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Pre-Qualified Rate MOM',
      columnDef: 'preQualifiedRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Credit Apps',
      columnDef: 'creditApps',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Credit Apps Prev. Month',
      columnDef: 'creditAppsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Credit Apps MOM',
      columnDef: 'creditAppsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Credit App Rate',
      columnDef: 'creditAppRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Credit App Rate Prev. Month',
      columnDef: 'creditAppRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Credit App Rate MOM',
      columnDef: 'creditAppRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Appointments',
      columnDef: 'appointments',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Appointments Prev. Month',
      columnDef: 'appointmentsPrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Appointments MOM',
      columnDef: 'appointmentsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointment Rate Prev. Month',
      columnDef: 'appointmentRatePrevMonth',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointment Rate MOM',
      columnDef: 'appointmentRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
  ];
}
