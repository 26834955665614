import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, zip, combineLatest, Subscription } from 'rxjs';
import { switchMap, tap, withLatestFrom, map } from 'rxjs/operators';
import { Enums } from '../../_shared/enums/enums';
import { FilterBarService } from '../../_shared/filter/filter-bar.service';
import { FilterActions } from '../../_shared/filter/store';
import * as Services from '../../_shared/services/services-index';
import { AppState } from '../../_store/app-state.model';
import { FilterDateService } from '../../_shared/filter/filter-date.service';
import { DateFilterValue, Filter, FilterTypes, FilterConfigReport } from '../../_shared/filter/filter.model';
import * as moment from 'moment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import * as constants from '../../_shared/constants/constants'
import { calendar } from 'ngx-bootstrap/chronos/moment/calendar';

interface PickerConfig {
  showCustom?: boolean;
  selected: DateFilterValue;
  items: DateFilterValue[];
  quarters: DateFilterValue[];
}

@Component({
  selector: 'layout-sddaterangepicker',
  templateUrl: './sdDateRangePicker.component.html',
  styleUrls: ['./sdDateRangePicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SdDateRangePickerComponent implements OnInit {
  dateFilter$: Observable<Filter>;
  dateValueSelected$: Observable<DateFilterValue>;
  config$: Observable<PickerConfig>;
  salesMonthsEnabled$ = this.filterDateService.salesMonthsEnabled$;
  reportConfig: FilterConfigReport;
  locale: string;
  localeSub: Subscription;
  modeDisplayValue: string;
  startDateDisplay: string;
  endDateDisplay: string;
  startPlaceholder = 'Start';
  endPlaceholder = 'End';
  disableCalendarModeToggle = false;
  hideCalendarModeToggle = false;
  orgDateCode: number = null;
  constructor(
    private dateAdapter: DateAdapter<any>,
    private store$: Store<AppState>,
    private filterBarService: FilterBarService,
    private filterDateService: FilterDateService,
    private configService: Services.ConfigurationService,
    private localeService: Services.LocaleService,
    private translationService: Services.SharedTranslationService,
    private datePipe: DatePipe
  ) {

  }
  translateDisplayDate = (val) => this.datePipe.transform(val, 'mediumDate', undefined, this.locale);
  ngOnInit() {
    this.dateFilter$ = this.filterBarService.reportName$.pipe(
      tap(reportName => {
        this.reportConfig = this.configService.filter.filterConfig.filterReportConfigs[reportName];
        this.hideCalendarModeToggle = !!this.configService.filter.filterConfig.hideCalendarToggle;
        this.disableCalendarModeToggle = !!this.reportConfig.disableCalendarMode;
        // configure default state for 'useSalesMonths'
        const defaultDateMonthMode = this.reportConfig.defaultMonthDateMode || this.configService.filter.filterConfig.defaultMonthDateMode || 'calendar';
        this.filterDateService.applyDateMonthMode(defaultDateMonthMode);
      }),
      switchMap(reportName => this.filterBarService.getReportFilters(reportName)),
      map(filters => filters.find(f => f.type === FilterTypes.date))
    );
    this.dateValueSelected$ = this.dateFilter$.pipe(map(dateFilter => <DateFilterValue>dateFilter.selected[0]));
    this.config$ = this.loadPickerConfig();

    this.localeSub = this.localeService.locale$.subscribe(loc => {
      this.locale = loc;
      this.startPlaceholder = this.translationService.getLabelTranslation(this.startPlaceholder, loc);
      this.endPlaceholder = this.translationService.getLabelTranslation(this.endPlaceholder, loc);
      this.dateAdapter.setLocale(loc);
    });
  }

  loadPickerConfig(): Observable<PickerConfig> {
    return combineLatest([this.dateValueSelected$, this.salesMonthsEnabled$]).pipe(
      switchMap(([selected, salesMonthEnabled]) => {

        this.orgDateCode = salesMonthEnabled ? constants.orgDateCodes.sales : constants.orgDateCodes.calendar;

        return this.filterDateService.getDateRanges().pipe(
        map(dateRangeSet => {
          this.modeDisplayValue = this.getModeDisplayValue(selected);
          this.startDateDisplay = this.translateDisplayDate(selected.startDate);
          this.endDateDisplay = this.translateDisplayDate(selected.endDate);
          // TODO Get rid of the 'restrictDates' construct for something clearer
          // TODO fix naming since we had to wedge quarters in here so now we have 'items' and 'quarters' properties
          if (!this.reportConfig.restrictDates) {
            // custom
            return { selected: selected, showCustom: true, items: dateRangeSet.scenarios, quarters: dateRangeSet.quarters };
          } else {
            if (this.reportConfig.dateMode.dateModeId === Enums.dateModes.quarterly.dateModeId) {
              // quarters
              return { selected: selected, items: dateRangeSet.quarters, quarters: dateRangeSet.quarters };
            } else {
              // months
              return { selected: selected, items: this.translateMonthNames(dateRangeSet.months), quarters: dateRangeSet.quarters };
            }
          }

        }))}));
  }

  ngOnDestroy() {
    this.localeSub.unsubscribe();
  }

  toggleDateLock(filter: Filter) {
    this.store$.dispatch(FilterActions.toggleDateFilterLock({reportName: this.reportConfig.reportName, filter}));
  }

  toggleSalesMonthEnabled(evt: MatSlideToggleChange) {
    this.filterDateService.applyDateMonthMode(evt.checked ? 'sales' : 'calendar');
  }

  applyDateRange(selected: DateFilterValue) {
    this.filterBarService.updateFilterSelected(this.reportConfig.reportName, FilterTypes.date, [selected]);
  }

  applyCustomDateRange(startDate: Date, endDate: Date) {
    // we must set the datepicker value to english no matter where it is coming from
    // french canadian datepicker will send us a date we can't understand here, for example
    const translatedStartDate = this.datePipe.transform(startDate, 'MM/dd/yyyy', undefined, 'en');
    const translatedEndDate = this.datePipe.transform(endDate, 'MM/dd/yyyy', undefined, 'en');

    const sd = moment(translatedStartDate, 'MM/DD/YYYY');
    const ed = moment(translatedEndDate, 'MM/DD/YYYY');
    const daysDiff = ed.diff(sd, 'days');
    const ped = sd.clone().subtract(1, 'day');
    const psd = ped.clone().subtract(daysDiff, 'day');
    const pye = ed.clone().subtract(1, 'year');
    const pys = pye.clone().subtract(daysDiff, 'day');

    // we need to generate a date filter value from what was selected
    const selectedDateValue: DateFilterValue = {
      dateModeId: Enums.dateModes.custom.dateModeId,
      startDate: sd.toDate(),
      endDate: ed.toDate(),
      previousStartDate: psd.toDate(),
      previousEndDate: ped.toDate(),
      previousYearStartDate: pys.toDate(),
      previousYearEndDate: pye.toDate(),
      orgDateCode: this.orgDateCode
    };

    this.applyDateRange(selectedDateValue);
  }
  translateMonthNames(values: DateFilterValue[]) {
    const newValues = [...values];
    newValues.map(v => {
      const year = v.displayName.split(' ')[1];
      const translatedMonth = this.translationService.getLabelTranslation(v.displayName.split(' ')[0], this.locale);
      v.displayName = `${translatedMonth} ${year}`;
    });

    return newValues;
  }

  getModeDisplayValue(value: DateFilterValue) {
    const dateMode = this.filterDateService.lookupDateMode(value.dateModeId).displayName;
    return this.translationService.getLabelTranslation(dateMode, this.locale);
  }
}
