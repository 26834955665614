import { Location } from '@angular/common';
import { Injectable, Injector, Inject, ReflectiveInjector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, Router, UrlSegment } from '@angular/router';
import { IAppInsightsData, IAuthenticationInfo } from '../../models/models-index';
import { environment } from '../../../../environments/environment';
import { AppInsights } from 'applicationinsights-js';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { tap } from 'rxjs/operators';

export interface IPropDictionary {
    [key: string]: string;
}

export interface IMeasurementDictionary {
    [key: string]: number;
}

export interface IAppInsightService {
    trackPageView(options?: { pageName?: string, url?: string, props?: IPropDictionary, measurements?: IMeasurementDictionary }): void;
    trackEvent(eventName: string, options?: { props?: IPropDictionary, measurements?: IMeasurementDictionary }): void;
    trackException(ex: Error, options?: { handledAt?: string, props?: IPropDictionary, measurements?: IMeasurementDictionary }): void;
    trackTrace(message: string, options?: { props?: IPropDictionary, measurements?: IMeasurementDictionary }): void;
}

@Injectable({ providedIn: 'root'})
export class AppInsightsService {

    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.applicationInsights.instrumentationKey
    };

    applicationHostDomain: string;

    user: IAuthenticationInfo;

    constructor(
      private store: Store<AppState>,
      private location: Location
    ) {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }

        this.store.select(AppSelectors.selectLoggedInUser).pipe(
          tap(user => this.user = user)
        ).subscribe();
    }

    trackPageView(options?: { pageName?: string, props?: IPropDictionary, measurements?: IMeasurementDictionary }): void {
        options = options || {};
        options.pageName = options.pageName || this.getDefaultPageName();
        const url = this.getDefaultUrl();

        options.props = this.getProps(options.props);
        options.measurements = this.getMeasurements(options.measurements);

        AppInsights.trackPageView(options.pageName, url, options.props, options.measurements);
    }

    trackEvent(
      eventName: string,
      options?: { props?: IPropDictionary, measurements?: IMeasurementDictionary,
        routeInfo?: ActivatedRouteSnapshot }): void {
        options = options || {};
        options.props = this.getProps(options.props, options.routeInfo);
        options.measurements = this.getMeasurements(options.measurements);

        AppInsights.trackEvent(eventName, options.props, options.measurements);
    }

    trackException(ex: Error, options?: { handledAt?: string, props?: IPropDictionary, measurements?: IMeasurementDictionary }): void {
        options = options || {};
        AppInsights.trackException(ex, options.handledAt, options.props, options.measurements);
    }

    trackTrace(message: string, options?: { props?: IPropDictionary }): void {
        options.props = this.getProps(options.props);
        AppInsights.trackTrace(message, options.props, AI.SeverityLevel.Warning);
    }

    setHostDomain(host: string) {
        this.applicationHostDomain = host;
    }

    private getProps(props: IPropDictionary, routeInfo?: ActivatedRouteSnapshot): IPropDictionary {
        props = props || {};

        const defaults = {
            App: environment.appName,
            UserName: '',
            ClientUserName: '',
            FirstName: '',
            LastName: '',
            UserRole: '',
            RoleEntity: ''
        };

        if (this.user) {
          defaults.UserName = this.user.userName;
          defaults.ClientUserName = this.user.clientUserName,
          defaults.FirstName = this.user.firstName;
          defaults.LastName = this.user.lastName;
          defaults.UserRole = this.user.role;
          defaults.RoleEntity = this.user.roleEntity;
        }

        if (routeInfo) {
            defaults['RoutePath'] = routeInfo.routeConfig.path;

            if (routeInfo && routeInfo.data) {
                defaults['Title'] = routeInfo.data.title;
            }
            routeInfo.params.forEach(el => {
              const [key, value] = el;
              defaults['PathParam_' + key] = value;
            });
        }

        props = {...defaults, ...props};
        return props;
    }

    private getMeasurements(measurements: IMeasurementDictionary): IMeasurementDictionary {
        measurements = measurements || {};
        return measurements;
    }

    private getDefaultPageName(): string {
        return '???';
    }

    private getDefaultUrl(): string {
        let path = this.location.path() || '';
        const idx = path.lastIndexOf('/');
        if (idx === path.length - 1) {
            // If path ends in slash, get rid of it
            path = path.substring(0, path.length - 1);
        }
        return path;
    }
}
