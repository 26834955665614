import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterState } from './filter-state.model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, mergeMap, map, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { ConfigurationService } from '../../services/services-index';
import { FilterBarService } from '../filter-bar.service';
import { FilterActions } from './action-types';
import * as FilterModels from '../filter.model';
import { FilterSelectors } from './selector-types';
import { FilterDateService } from '../filter-date.service';

@Injectable()
export class FilterEffects {

  constructor(
    private store$: Store<FilterState>,
    private actions$: Actions,
    private filterBarService: FilterBarService,
    private filterDateService: FilterDateService,
    private configService: ConfigurationService,
    private router: Router) { }

  @Effect()
  initializeReportFilters$ = this.actions$.pipe(
    ofType(FilterActions.initializeFilters),
    exhaustMap(action => of(action).pipe(
      switchMap(() => this.filterBarService.getInitialReportFilters(action.reportName).pipe(
        map(({ filters, lockedFilters }) => FilterActions.initializeFiltersSuccess({ reportName: action.reportName, filters, lockedFilters }))
      ))
    ))
  );

  @Effect()
  removeFilter = this.actions$.pipe(
    ofType(FilterActions.removeFilter),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectCurrentReportFilters),
        this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, reportFilters, filterState]) => {
      const reportName = action.reportName;
      const newFilters = reportFilters.filters.filter(f => f.type !== action.filterType);
      const lockedFilters = filterState.lockedFilters.filter(f => f.type !== action.filterType);

      return FilterActions.removeFilterSuccess({ reportName, filters: newFilters, lockedFilters });
    })
  );

  @Effect()
  toggleFilterLock = this.actions$.pipe(
    ofType(FilterActions.toggleFilterLock, FilterActions.toggleDateFilterLock),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState))
    )),
    map(([action, filterState]) => {
      const newFilter = { ...action.filter };

      newFilter.locked = !newFilter.locked;

      const newState = { ...filterState };

      // set locked filters state
      if (newFilter.locked) {
        // take out of the explicitUnlockedFilters
        newState.explicitUnlockedFilters = [...newState.explicitUnlockedFilters.filter(f => f !== newFilter.type)];
        newState.lockedFilters = [...newState.lockedFilters, newFilter];
      } else {
        newState.explicitUnlockedFilters = [...newState.explicitUnlockedFilters, newFilter.type];
        newState.lockedFilters = newState.lockedFilters.filter(lf => lf.type !== newFilter.type);
      }

      // set report filters state
      newState.filters.forEach(reportFilters => {
        const filters = reportFilters.filters;
        const currentFilter = filters.find(f => f.type === newFilter.type);

        const newFilters = [...filters];

        if (currentFilter) {
          // the filter already exists on the report and we're just replacing it
          const indexOfCurrentFilter = filters.indexOf(currentFilter);
          newFilters[indexOfCurrentFilter] = newFilter;
        } else if (newFilter.locked) {
          // the filter doesn't already exist on the report and we need to see if it can, if so, we need to add it
          if (this.configService.filter.filterConfig.filterReportConfigs[reportFilters.reportName].filters.includes(newFilter.type)) {
            // we need to arbitrarily add the filter
            newFilters.push(newFilter);
          }
        }

        const newReportFilters = [...newState.filters];

        newReportFilters.splice(
          newReportFilters.findIndex(rf => rf.reportName === reportFilters.reportName),
          1,
          { reportName: reportFilters.reportName, filters: newFilters }
        );

        newState.filters = newReportFilters;
      });

      return FilterActions.toggleFilterLockSuccess({ filterState: newState });
    })
  );

  @Effect()
  updateCurrentReportFilterSelected = this.actions$.pipe(
    ofType(FilterActions.updateCurrentReportFilterSelected),
    mergeMap(action => of(action).pipe(
      withLatestFrom(this.store$.select(FilterSelectors.selectFilterState), this.filterDateService.dateRanges$)
    )),
    map(([action, state, dateRanges]) => {
      const newFilterState = { ...state };

      // we need to generate a fresh filter based on the new selected values
      // we first want to see if there's a locked filter and if so, update its selected
      // this also makes the assumption that since it's a locked filter, it's already present on all supported reports
      const existingLocked = newFilterState.lockedFilters.find(f => f.type === action.filterType);

      if (existingLocked) {
        const newExistingLockedFilter = {...existingLocked, selected: action.selected};

        newFilterState.lockedFilters = [...newFilterState.lockedFilters.filter(f => f.type !== action.filterType), newExistingLockedFilter];
      } else {
        // we first want to see if the report has the filter in question already
        // if not, we'll get it from defaults and add it
        const reportFilters = newFilterState.filters.find(f => f.reportName === action.reportName);
        const existingReportFilter = reportFilters.filters.find(f => f.type === action.filterType);
        const filterIndex = existingReportFilter ? reportFilters.filters.indexOf(existingReportFilter) : -1;

        let newOrUpdatedFilter: FilterModels.Filter;

        // TODO Cleanup
        if (existingReportFilter) {
          newOrUpdatedFilter = { ...existingReportFilter, selected: action.selected };
        } else {
          // get default filter state, set selected, and add it to the report
          const defaultFilter = { ...this.configService.filter.filterConfig.FILTER_CONFIG[action.filterType] };

          if (!defaultFilter) {
            throw new Error(`Unable to get default filter for ('${action.filterType}'). Did you setup FILTER_CONFIG correctly?`);
          }
          
          newOrUpdatedFilter = { ...defaultFilter, selected: action.selected };
        }

        let updatedFilters: FilterModels.Filter[] = [];

        if (filterIndex >= 0) {
          updatedFilters = [...reportFilters.filters];
          updatedFilters[filterIndex] = newOrUpdatedFilter;
        } else {
          updatedFilters = [...reportFilters.filters, newOrUpdatedFilter];
        }

        // update report filters
        const newReportFilters = [...newFilterState.filters];

        newReportFilters.splice(
          newFilterState.filters.findIndex(f => f.reportName === reportFilters.reportName),
          1,
          { reportName: reportFilters.reportName, filters: updatedFilters }
        );
        newFilterState.filters = newReportFilters;
      }

      return FilterActions.updateCurrentReportFilterSelectedSuccess({ filterState: newFilterState });
    })
  );
}
