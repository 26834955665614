import * as FilterPanelTypes from '../../_shared/sd-filter-panel-module/types/types';
import * as FilterModels from './filter.model';

export class ValueConverter {
    convertToSimpleFilterValue(panelValue: FilterPanelTypes.SimpleValue): FilterModels.SimpleFilterValue {
        return {
            value: panelValue.value,
            display: panelValue.display
        };
    }

    convertToOrgFilterValue(panelValue: FilterPanelTypes.IFilterTreeNode): FilterModels.OrgFilterValue {
        return {...panelValue.orgFilterValue};
    }

    convertToPanelSimpleValue(filterValue: FilterModels.SimpleFilterValue): FilterPanelTypes.SimpleValue {
        return {
            value: filterValue.value,
            display: filterValue.display
        };
    }

    convertToFilterTreeNode(orgFilterValue: FilterModels.OrgFilterValue): FilterPanelTypes.IFilterTreeNode {
        return {
            value: orgFilterValue.value,
            display: orgFilterValue.display,
            orgFilterValue: { ...orgFilterValue }
        };
    }

    public convertToOrgEntityType(entity: string): FilterModels.OrgEntityType {
        switch (entity) {
            case 'national':
                return 'national';
            case 'orgcode3':
                return 'orgcode3';
            case 'orgcode2':
                return 'orgcode2';
            case 'orgcode1':
                return 'orgcode1';

            default:
                return 'dealer';
        }
    }
}
