import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as Models from '../../models/models-index';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../services-index';

@Injectable({ providedIn: 'root' })
export class UsersService {

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,
    private appInsights: AppInsightsService) { }

  getUsers(reportRequest: any, reportType?: string): Observable<Models.UserResponseModel> {
    reportRequest.reportType = reportType || reportRequest.reportType;

    return this.http.post<Models.UserResponseModel>(
      environment.baseApiUri + '/getUsers',
      JSON.stringify(reportRequest),
      { headers: this.headers })
      .pipe(
        catchError(err => {
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  getUserByUserId(reportRequest: any, reportType?: string): Observable<Models.User> {
    reportRequest.reportType = reportType || reportRequest.reportType;
    return this.http.post<Models.User>(environment.baseApiUri + '/getUserById', JSON.stringify(reportRequest), { headers: this.headers })
      .pipe(
        catchError(err => {
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  getUserByUserName(request: string): Observable<Models.User> {
    const requestModel: Models.UserByNameRequestModel = {
      userName: request
    };

    return this.http.post<Models.User>(environment.baseApiUri + '/getUserByName', requestModel, { headers: this.headers })
      .pipe(
        catchError(err => {
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  getRoles(): Observable<Models.Role[]> {
    return this.http.get<Models.Role[]>(environment.baseApiUri + '/getRoles', { headers: this.headers })
      .pipe(
        catchError(err => {
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  getOrgLookupTypes(): Observable<Models.OrgLookupType[]> {
    return this.http.get<Models.OrgLookupType[]>(environment.baseApiUri + '/getOrgLookupTypes', { headers: this.headers })
      .pipe(
        catchError(err => {
          appInsights.trackException(err);
          return this.handleError(err);
        })
      );
  }

  private handleError(err: any) {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    this.appInsights.trackException(err);
    console.error(err);
    return throwError(errorMessage);
  }
}
