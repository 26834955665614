import { Enums, IPrintingOption } from '../../enums/enums';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export interface IDataTablePrintingOptions {
  title: string;
  expandAll?: boolean;
  printingOption: IPrintingOption;
}

@Injectable()
export class DataTableService {
  // Default states
  public heightExpanded: boolean = false;

  private dataUpdated = new BehaviorSubject<any>([]);
  private selectedTrendUpdated = new Subject<any>();
  private printButtonClicked = new Subject<IDataTablePrintingOptions>();
  private kpiPrintButtonClicked = new Subject<IDataTablePrintingOptions>();
  private toggleHierarchyTableExpando = new Subject<any>();
  private togglePagedSortedTableExpando = new Subject<any>();
  private toggleTrendTableExpando = new Subject<any>();
  dataUpdated$ = this.dataUpdated.asObservable();
  selectedTrendUpdated$ = this.selectedTrendUpdated.asObservable();
  printButtonClicked$ = this.printButtonClicked.asObservable();
  kpiPrintButtonClicked$ = this.kpiPrintButtonClicked.asObservable();
  toggleHierarchyTableExpando$ = this.toggleHierarchyTableExpando.asObservable();
  togglePagedSortedTableExpando$ = this.togglePagedSortedTableExpando.asObservable();
  toggleTrendTableExpando$ = this.toggleTrendTableExpando.asObservable();

  constructor() {
    
  }

  toggleExpando(dataTableType: string, expandType: string): void {
    switch (dataTableType) {
      case Enums.dataTableTypes.hierarchy:
        this.heightExpanded = !this.heightExpanded;
        this.toggleHierarchyTableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      case Enums.dataTableTypes.pagedSorted:
        this.heightExpanded = !this.heightExpanded;
        this.togglePagedSortedTableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      case Enums.dataTableTypes.trend:
        this.heightExpanded = !this.heightExpanded;
        this.toggleTrendTableExpando.next({ expandType: expandType, expanded: this.heightExpanded });
        break;
      default:
        break;
    }
  }

  resetExpansionState() {
    this.heightExpanded = false;
  }

  updateData(data: any): void {
    this.dataUpdated.next(data);
  }

  updateSelectedTrend(activeTrendMetrics: string[]): void {
    this.selectedTrendUpdated.next(activeTrendMetrics);
  }

  printCurrentData(printingOptions: IDataTablePrintingOptions): void {
    this.printButtonClicked.next(printingOptions);
  }

  printKpiTable(printOptions: IDataTablePrintingOptions): void {
    this.kpiPrintButtonClicked.next(printOptions);
  }
}
