import { Injectable } from '@angular/core';
import { IAuthenticationInfo, IOrgLookup } from '../models/models-index';
import { OrgEntityType } from '../filter/filter.model';

@Injectable({
    providedIn: 'root'
})
export class OrgLookupService {
    private _orgLookups: IOrgLookup[];
    constructor() {
    }

    setOrgLookups(orgLookups: IOrgLookup[], user: IAuthenticationInfo) {
        
        let userOrgLookupTypeIds: number[] = [];
        let ids = [];

        if(!!user.orgLookupTypeIds) {
          user.orgLookupTypeIds.split(",").forEach(id => { ids.push(parseInt(id, 10)) });
        }
        
        userOrgLookupTypeIds = [...ids];

        this._orgLookups = userOrgLookupTypeIds.length === 0 
          ? orgLookups
          : [...orgLookups.filter(ol => userOrgLookupTypeIds.indexOf(ol.orgLookupTypeId) != -1)];
    }

    get orgLookups(): IOrgLookup[] {
        return [...this._orgLookups];
    }

    getByName(orgLookupTypeId: number, name: string) {
        const [filtered] = this._orgLookups.filter(ol => ol.orgLookupTypeId === orgLookupTypeId
            && (ol.orgCode3Name === name || ol.orgCode2Name === name
                || ol.orgCode1Name === name));

        return filtered;
    }

    getEntityTypeName(entityType: OrgEntityType) {
        switch (entityType) {
            case 'national':
                return 'National';
            case 'orgcode3':
                return 'Region';
            case 'orgcode2':
                return 'Area';
            case 'orgcode1':
                return 'Market';
            case 'dealer':
                return 'Dealer';
            default:
                return undefined;
        }
    }

    getEntityTypePropertyName(entityType: OrgEntityType) {
        switch (entityType) {
            case 'national':
                return 'National';
            case 'orgcode3':
                return 'orgCode3';
            case 'orgcode2':
                return 'orgCode2';
            case 'orgcode1':
                return 'orgCode1';
            case 'dealer':
                return 'dealer';
            default:
                return undefined;
        }
    }
}
