
<div class="sd-selector" fxLayout="row" fxLayoutAlign="end center" *ngIf="selectedMetric$ | async as selectedMetric">
    <mat-form-field floatLabel="never" class="">
      <mat-label>{{selectedMetric.name}}</mat-label>
      <mat-select [matMenuTriggerFor]="metrics"></mat-select>
      <mat-menu #metrics="matMenu">
        <button mat-menu-item *ngFor="let metric of metricOptions$ | async" (click)="updatePrimaryMetric(metric)">{{metric.name}}</button>
      </mat-menu>
    </mat-form-field>
</div>
  