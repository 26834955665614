export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Shift Digital',
  hostName: 'analytics.shiftdigital.com',
  environmentName: 'production',
  appName: 'Shift Digital Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '8c4ed233-0a40-4f11-bc13-9d35c63e4b5c'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#F7971C', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://sd-analytics-api-demo.azurewebsites.net/api',
  authUri: 'https://sd-analytics-api-demo.azurewebsites.net/token',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false
};
