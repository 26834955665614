<div class="card card-no-border mat-elevation-z1">
    <div class="card-header">
        <div class="pull-left" *ngIf="showHeaderLabel">
            <h5><i class="fa fa-line-chart fa-primary pad-right"></i><b>{{'Trend' | translate: chart.locale}}</b></h5>
        </div>
        <div class="pull-right actions">
            <metric-simple-select *ngIf="!!metricOneSelectConfig" [config]="metricOneSelectConfig" (selectionChange)="applyMetricOneChange($event)"></metric-simple-select>
            <metric-simple-select style="padding-left: 25px;" *ngIf="!!metricTwoSelectConfig" [config]="metricTwoSelectConfig" (selectionChange)="applyMetricTwoChange($event)"></metric-simple-select>
        </div>
    </div>
    <dual-line-chart *ngIf="graphDataObject && metricOneConfig && metricTwoConfig"
        [chartConfig]="graphDataObject"
        [metricOneConfig]="metricOneConfig"
        [metricTwoConfig]="metricTwoConfig"
        [locale]="chart.locale"
    >
    </dual-line-chart>
</div>
