import { Injectable } from '@angular/core';
import { WebsiteTranslationConfig } from '../../../_config/translation/website/website.translation.config';


@Injectable()
export class WebsiteTranslationService {
  websiteTranslationConfig = new WebsiteTranslationConfig;


  getWebsiteMetricNameTranslation(metricId: string, defaultLabel: string, locale: string) {
    if (locale === 'en') {
      return defaultLabel;
    }

    return this.websiteTranslationConfig.websiteMetrics[locale][metricId] ?? '';
  }

  getWebsiteReportTitleTranslation(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.websiteTranslationConfig.websiteReportTitles[locale][cleansedValue];
  }

  getWebsiteLabelTranslation(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }

    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    const label = this.websiteTranslationConfig.websiteLabels[locale][cleansedValue];
    return label;
  }

  getWebsiteChartDimension(value: string, locale: string) {
    if (locale === 'en') {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.websiteTranslationConfig.websiteChartDimensions[locale][cleansedValue] || value;
  }

  getWebsiteChannels(value: string, locale: string) {
    if (!locale || locale === 'en' || !value) {
      return value;
    }
    const cleansedValue = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.websiteTranslationConfig.websiteChannels[locale][cleansedValue] || value;
  }

}
