import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'website',
      category: 'website',
      default: true,
      defaultRoute: '/website/overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'scorecard',
          displayName: 'Scorecard',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'referrer',
          displayName: 'Referrer',
          iconClass: 'fa fa-share',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'provider',
          displayName: 'Provider',
          iconClass: 'fa fa-cogs',
          roles: undefined
        }
      ]
    },
    {
      id: 'digAd',
      category: 'digAd',
      default: false,
      defaultRoute: '/digAd/overview',
      displayName: 'Digital Advertising',
      displayInNav: true,
      iconClass: 'fa fa-desktop',
      roles: undefined,
      children: [
        {
          parent: '/digAd',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'scorecard',
          displayName: 'Scorecard',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'summary',
          displayName: 'Summary',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'provider',
          displayName: 'Provider',
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-cogs',
          roles: undefined
        }
      ]
    },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: false,
      defaultRoute: '/provider-tools/digital-retailing',
      displayName: 'Provider Tools',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
      children: [{
        parent: '/provider-tools',
        route: 'chat-overview',
        displayName: 'Chat Overview',
        pdfEnabled: false,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-users',
        roles: undefined
      }, {
        parent: '/provider-tools',
        route: 'trade-in',
        displayName: 'Trade-In (BETA)',
        pdfEnabled: false,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-exchange',
        roles: undefined
      }, {
        parent: '/provider-tools',
        route: 'digital-retailing',
        displayName: 'Digital Retailing (BETA)',
        pdfEnabled: false,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-users',
        roles: undefined
      }]
    },
  ];
}
