
<div class="filter-content-container" style="display: flex; flex-direction: column;">

    <div class=""
        style="display: flex; flex-direction: row; justify-content: space-evenly; border-bottom: 1px solid #ccc;">

        <div class=""
            style="height: 50px; width: 50%; border-right: 1px solid #ccc; display: flex; align-items: center;">

            <div class="filter-content-wrapper" style="padding: 0; width: 100%;">

                <mat-form-field style="width: 100%; max-height: 49px; padding-left: 10px;" appearance="outlined" appearance="outlined">

                    <input #searchInput matInput type="text" placeholder={{searchPlaceholderText}} [(ngModel)]="searchTerm"
                        (focus)="searchHasFocus = true" (focusout)="searchHasFocus = false">

                    <button mat-icon-button *ngIf="searchHasFocus || searchTerm" matSuffix
                        (click)="clearSearchTerm($event)">

                        <mat-icon>clear</mat-icon>

                    </button>

                    <button mat-icon-button *ngIf="!searchHasFocus && !searchTerm" matSuffix>

                        <mat-icon>search</mat-icon>

                    </button>


                </mat-form-field>
                <mat-progress-bar *ngIf="showProgress" mode="determinate" value="400" style="height: 1px;">
                </mat-progress-bar>
            </div>

        </div>

        <div class="" style="height: 50px; width: 50%; align-items: center; display: flex; align-items: center;">

            <div class="filter-content-wrapper" style="padding: 0 10px; width: 100%;">

                <span *ngIf="!selected.length">{{noneSelectedText}}</span>

                <div *ngIf="selected.length" class="selected-detail-wrapper"
                    style="display: flex; justify-content: space-between; align-items: center;">

                    <div>{{selected.length}} {{selectedText}}</div>

                    <button mat-button [ngClass]="{'clear-all-button-wrapped': (locale !== 'en')}" (click)="clearAllSelected()">
                        <span  style="color: #4285f4">{{clearSelectedText}}</span>
                    </button>

                </div>

            </div>

        </div>

    </div>

    <div class="" style="display: flex; flex-direction: row; justify-content: space-evenly;">

        <div class=""
            style="width: 50%; border-right: 1px solid #ccc; display: flex; flex-direction: column; overflow-y: auto;">

            <div class=""
                style="height: 50px; display: flex; align-items: center; overflow-y: auto; border-bottom: 1px solid #ccc;">

                <div class="filter-content-wrapper" style="padding: 0 10px;">

                    <mat-checkbox [indeterminate]="isIndeterminate()" [checked]="isChecked()" *ngIf="!singleSelectionMode"
                        (change)="headerCheckboxChanged($event)" labelPosition="right">{{checkBoxHeaderLabel}}
                    </mat-checkbox>
                    
                    <mat-label *ngIf="!!singleSelectionMode">{{checkBoxHeaderLabel}}</mat-label>

                </div>

            </div>

            <div class="" style="overflow-y: auto; height: 226px;">

                <div class="filter-content-wrapper" style="display: flex; flex-direction: column;">

                    <mat-selection-list class="selection-list" #panelDataSelectList [compareWith]="compareOptions">

                        <mat-list-option *ngFor="let option of filteredPanelData" class="mat-primary"
                            checkboxPosition="before" (click)="filterDataOptionChanged(option)" [value]="option">{{option.display}}
                        </mat-list-option>

                    </mat-selection-list>

                </div>

            </div>

        </div>

        <div class="" style="width: 50%; height: 277px; overflow-y: auto;">

            <div class="filter-content-wrapper">

                <div class="selected-list-wrapper" style="width: 100%;">

                    <div *ngFor="let option of selected; let idx = index;" class="selected-list-item container">

                        <!-- <div class="selected-item-ripple-container" matRipple [matRippleTrigger]="idx"></div> -->

                        <div matRipple [matRippleColor]="rippleColor" class="selected-list-item-wrapper"
                            (click)="handleRemoveSelectedFilter(option)" style="padding: 0 0 0 10px;">

                            <div>{{option.display}}</div>
                            <button mat-icon-button>
                                <mat-icon matListIcon>cancel</mat-icon>
                            </button>

                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>

</div>
