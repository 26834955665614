import { Injectable } from '@angular/core';
import { SharedTranslationConfig } from '../../../_config/translation/shared/shared.translation.config';

@Injectable()
export class SharedTranslationService {
  sharedTranslations = new SharedTranslationConfig;
  appSectionTranslations: any;
  navLabelTranslations: any;
  constructor() { }
  getLabelTranslation(value: string, locale?: string) {
    if (locale === 'en' || !locale || !value) {
      return value;
    }

    const val = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    return this.sharedTranslations.labels[locale][val] ?? value;
  }
}

