
import { Enums, IEnums } from '../../enums/enums';
import { } from '../cache/localCache.service';
import { Injectable } from '@angular/core';
import * as Models from '../../models/models-index';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { tap, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../../_store/app-state.model';
import { Store, select } from '@ngrx/store';
import { AppSelectors } from '../../../_store/selector-types';
import { FilterBarService } from '../../filter/filter-bar.service';
import { ConfigurationService } from '../config/config.service';

export interface IFilterDates {
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
}

export interface IDaterangeRestrictions {
  restrict: boolean;
  numberOfMonthsToShow: number;
}

@Injectable({ providedIn: 'root'})
export class FilterService {

  private enums: IEnums = Enums;
  filterName: string;
  activePageTitle$ = new BehaviorSubject('Please set a page title');

  orgLookups: Models.IOrgLookup[];
  currentSalesMonth: Date;
  dayOfSalesMonth: number;

  private secondaryNavResized = new Subject<string>();
  private daterangeRestrictions = new Subject<IDaterangeRestrictions>();

  secondaryNavResized$ = this.secondaryNavResized.asObservable();
  daterangeRestrictions$ = this.daterangeRestrictions.asObservable();

  currentFilterModel: Models.IFilterModel;
  defaultFilter: Models.IFilterModel;

  // Observables for requesting and returning filter breadcrumbs
  private filterBreadcrumbsRequested = new Subject();
  private filterBreadcrumbsReturned = new Subject<string[]>();
  filterBreadcrumbsRequested$ = this.filterBreadcrumbsRequested.asObservable();
  filterBreadcrumbsReturned$ = this.filterBreadcrumbsReturned.asObservable();

  filter$: Observable<Models.IFilterModel>;
  currentReportName$: Observable<string>;

  // Auth/User info
  private authInfo: Models.IAuthenticationInfo;
  constructor(
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private filterBarService: FilterBarService,
    private configService: ConfigurationService) {
      this.filter$ = this.store.select(AppSelectors.selectCurrentRouteData).pipe(
        map(routeData => routeData.reportName),
        switchMap(reportName => this.filterBarService.getReportFilterModel(reportName))
      );
      // we can get this anywhere we need from this.filters$ or refrence this directly
      //  in this component - this is referenced in a lot of unused legacy / unconverted report functions
      this.filterBarService.getDefaultFilterModel().pipe(
        tap(defaultFilter => this.defaultFilter = defaultFilter)
      ).subscribe();

      this.currentReportName$ = this.store.select(AppSelectors.selectCurrentRouteData).pipe(
        map(data => data.reportName || 'UNKNOWN')
      );
  }

  initialize() {
    this.authInfo = this.authService.getUser();
  }

  getCurrentFilterModel() {
    return this.currentFilterModel;
  }

  getDefaultFilterModel() {
    return this.defaultFilter;
  }

  setActivePageTitle(title: string) {
    this.activePageTitle$.next(title);
  }

  getActivePageTitle(): string {
    return '';
  }

  restrictDaterangeOptions(options: IDaterangeRestrictions) {
    this.daterangeRestrictions.next(options);
  }

  triggerNavResizeEvent(filterState: string) {
    this.secondaryNavResized.next(filterState);
  }

  // Requesting/returning filter breadcrumbs
  requestBreadcrumbString() {
    this.filterBreadcrumbsRequested.next();
  }

  returnBreadcrumbString(breadcrumbs: string[]) {
    this.filterBreadcrumbsReturned.next(breadcrumbs);
  }

  convertStringToStringArray(value: string): string[] {
    if (!value) {
      return null;
    } else {
      const array = value.split(',');
      return array;
    }
  }

  convertStringToNumberArray(value: string): number[] {
    if (!value) {
      return null;
    } else {
      const array = value.split(',').map(Number);
      return array;
    }
  }

}
