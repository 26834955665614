import { Component, Input } from '@angular/core';
import { Enums } from '../enums/enums';

@Component({
  selector: 'combined-data-table',
  templateUrl: './combinedDataTable.component.html'
})
export class CombinedDataTableComponent {

  enums = Enums;

  @Input() tableType: string;

  constructor() {

  }
}
