import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Enums, IEnums, IRouteDetails } from '../_shared/enums/enums';
import * as Models from '../_shared/models/models-index';
import { IAuthenticationInfo, User, IOrgLookup, IDealerLookup, IRouteConfig } from '../_shared/models/models-index';
import { FilterService, LocalCacheService, ConfigurationService, LocaleService } from '../_shared/services/services-index';
import * as Modals from '../_shared/modals/modals-index';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { States } from '../_store/app.reducer';
import { selectLoggedInUser, isLoggedIn, selectOrgLookups } from '../_store/app.selectors';
import { map, tap, take } from 'rxjs/operators';
import { AppSelectors } from '../_store/selector-types';
import { LayoutHelperService } from './layoutHelper.service';
import { rotate90, rotate180 } from '../_shared/utilities/app-animations';
import { FilterBarService } from '../_shared/filter/filter-bar.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'layout-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [rotate90, rotate180],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidebarComponent implements OnInit, OnDestroy {
  @ViewChildren('currentMenuTrigger') menuTriggers: QueryList<MatMenuTrigger>;
  // @ViewChild('leadMenuTrigger') leadMenuTrigger: MatMenuTrigger;
  // @ViewChild('managementMenuTrigger') managementMenuTrigger: MatMenuTrigger;
  // @ViewChild('websiteMenuTrigger') websiteMenuTrigger: MatMenuTrigger;
  // @ViewChild('salesMenuTrigger') salesMenuTrigger: MatMenuTrigger;
  // @ViewChild('dealerResourceMenuTrigger') dealerResourceMenuTrigger: MatMenuTrigger;
  locale: string;
  subscriptions: Subscription[] = [];
  orgLookups: IOrgLookup[];
  orgLookups$ = this.store$.select(AppSelectors.selectOrgLookups);
  dealerLookups$ = this.store$.select(AppSelectors.selectDealerLookups);
  routeConfigs: Models.IRouteConfig[] = undefined;
  // Auth/User info
  authInfo: IAuthenticationInfo;
  authInfo$ = this.store$.pipe(select(selectLoggedInUser));
  isAuthenticated$ = this.store$.pipe(select(isLoggedIn));
  isRegionManager$ = this.authInfo$.pipe(
    map(authInfo => this.configService.role.isOrg2Role(authInfo.role)));

  constructor(
    public configService: ConfigurationService,
    public dialog: MatDialog,
    private localeService: LocaleService,
    private filterBarService: FilterBarService,
    public layoutHelper: LayoutHelperService,
    private router: Router,
    private store$: Store<States.AppState>) {
    this.routeConfigs = this.configService.routing.routingConfig.routes.filter(r => r.displayInNav === true) || [];
  }

  enums: IEnums = Enums;

  ngOnInit() {

    this.subscriptions.push(
      this.authInfo$.subscribe(authInfo => this.authInfo = authInfo),

      this.localeService.locale$.pipe(tap(loc => this.locale = loc)).subscribe(),

      this.store$.select(selectOrgLookups).pipe(
        tap(orgLookups => this.orgLookups = orgLookups)).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe);
  }

  showNav = (routeConfig: Models.IRouteConfig) => {
    const show = this.layoutHelper.showNavItem(this.authInfo, routeConfig);

    return show;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  openMenu(menuTrigger: MatMenuTrigger) {
    // Voodoo time:
    const selectedMenuId = menuTrigger['_element']['nativeElement']['id'];
    const showChildren = !!this.routeConfigs.find(r => r.id === selectedMenuId).children;
    const allMenus = this.menuTriggers;

    allMenus.forEach(m => m.closeMenu());
    if (!!showChildren) {
      menuTrigger.openMenu();
    }
  }

  openExternalTool(routeConfig: IRouteDetails) {
    let url = '';

    if (routeConfig.route === 'retailerTools') {
      const auth = this.authInfo;
      url += environment.retailerToolsBaseUri;
      if (!!environment.retailerToolsIncludeAuth) {
        url += `/auth?userName=${auth.clientUserName}&firstName=${auth.firstName}&lastName=${auth.lastName}&role=${auth.role}&roleEntity=${auth.roleEntity}&handoffToken=a3iojd7adiou`;
      }
    }
    else if (routeConfig.route === 'mysteryShop') {
      url = this.layoutHelper.generateMysteryShopUrl(this.authInfo);
    }

      window.open(url);
  }
}
