import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Models from '../_shared/models/models-index';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { Enums, IEnums, IRouteDetails } from '../_shared/enums/enums';
import { IAuthenticationInfo } from '../_shared/models/models-index';
import { ConfigurationService } from '../_shared/services/services-index';
import { environment } from '../../environments/environment';

@Injectable()
export class LayoutHelperService {

  constructor(
    private router: Router,
    private configService: ConfigurationService
  ) { }

  filterModel: Models.IFilterModel;
  private sideNavToggleSubject = new BehaviorSubject<string>(!!environment.sidebarDefaultOpen ? 'open' : 'closed');
  sideNavToggleAction$ = this.sideNavToggleSubject.asObservable();
  routingConfig: Models.IRoutingConfig;
  enums: IEnums = Enums;
  currentCategory: string;
  expandedCategory: string;
  menuClass: string;
  currentChildren: IRouteDetails[];
  rotateMenuGlyph = false;
  rotateCategoryGlyph = false;

  toggleSidebar(toggleStatus: string) {
    let toggle: string;
    if (toggleStatus === 'open') {
      this.rotateMenuGlyph = false;
      toggle = 'closed';
    } else {
      toggle = 'open';
      this.rotateMenuGlyph = true;
    }
    this.sideNavToggleSubject.next(toggle);
  }

  updateExpandedCategory(category: string) {
    if (this.expandedCategory === category) {
      this.expandedCategory = this.currentCategory;
    } else {
      this.expandedCategory = category;
    }
  }

  updateCurrentCategory(): void {
    this.currentCategory = this.router.url.split('/')[1] || 'leads';
    this.expandedCategory = this.currentCategory;
  }

  initializeSidebarToggleState(): void {
    const defaultToggleState = !!environment.sidebarDefaultOpen ? 'open' : 'closed';
    this.sideNavToggleSubject.next(defaultToggleState);
  }

  showNavItem(authData: IAuthenticationInfo, routeData: Models.IRouteConfig) {
    const roles = routeData.roles || [];
    const userRole = authData.role;
    if (!!routeData.disableForDealerGroups && this.configService.role.isDealerGroupRole(authData.role)) {
      return false;
    }
    if (roles.length === 0) {
      return true;
    } else {
      return roles.indexOf(userRole) != -1;
    }
  }

  userHasLevelAccess(userRole: string, requiredRole: string): boolean {
    if (this.configService.role.isSysAdminRole(requiredRole)) {
      return this.configService.role.isSysAdminRole(userRole);
    }
    else if (this.configService.role.isCorporateRole(requiredRole)) {
      return this.configService.role.isSysAdminRole(userRole)
        || this.configService.role.isCorporateRole(userRole)
    }
    else if (this.configService.role.isOrg3Role(requiredRole)) {
      return this.configService.role.isSysAdminRole(userRole)
        || this.configService.role.isCorporateRole(userRole)
        || this.configService.role.isOrg3Role(userRole)
        ? true : false;
    }
    else if (this.configService.role.isOrg2Role(requiredRole)) {
      return this.configService.role.isSysAdminRole(userRole)
        || this.configService.role.isCorporateRole(userRole)
        || this.configService.role.isOrg3Role(userRole)
        || this.configService.role.isOrg2Role(userRole)
        ? true : false;
    }
    else if (this.configService.role.isOrg1Role(requiredRole)) {
      return this.configService.role.isSysAdminRole(userRole)
        || this.configService.role.isCorporateRole(userRole)
        || this.configService.role.isOrg3Role(userRole)
        || this.configService.role.isOrg2Role(userRole)
        || this.configService.role.isOrg1Role(userRole)
        ? true : false;
    }
    else if (this.configService.role.isDealerRole(requiredRole)) {
      return this.configService.role.isSysAdminRole(userRole)
        || this.configService.role.isCorporateRole(userRole)
        || this.configService.role.isOrg3Role(userRole)
        || this.configService.role.isOrg2Role(userRole)
        || this.configService.role.isOrg1Role(userRole)
        || this.configService.role.isDealerRole(userRole)
        ? true : false;
    } else {
      return false;
    }
  }

  // TODO: See if we need to modify this logic for Dealer Group users
  generateMysteryShopUrl(authInfo: IAuthenticationInfo): string {    
    let url = environment.mysteryShopBaseUri;;

    url += "/?userEmail=" + authInfo.userName; // this relies on us keeping the email-as-username convention
    url += "&firstName=" + authInfo.firstName;
    url += "&lastName=" + authInfo.lastName;
    
    let role = authInfo.role;

    if (this.configService.role.isCorporateRole(role) || this.configService.role.isSysAdminRole(role)) {
    } else if (this.configService.role.isOrg3Role(role)) {
      url += `&${this.configService.org.orgConfig.mysteryShopUrlOrgs.orgCode3Name}=` + authInfo.roleEntity;
    } else if (this.configService.role.isOrg2Role(role)) {
      url += `&${this.configService.org.orgConfig.mysteryShopUrlOrgs.orgCode2Name}=` + authInfo.roleEntity;
    } else if (this.configService.role.isOrg1Role(role)) {
      url += `&${this.configService.org.orgConfig.mysteryShopUrlOrgs.orgCode1Name}=` + authInfo.roleEntity;
    } else if (this.configService.role.isDealerRole(role)) {
      url += `&${this.configService.org.orgConfig.mysteryShopUrlOrgs.dealerName}=` + authInfo.roleEntity;
    }

    return url;
  }
}
