import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedHelpModel } from './shared-help.model';
import { Observable, of, Subscription } from 'rxjs';
import { ContentService } from '../services/api/content.service';
import { catchError, map, tap } from 'rxjs/operators';
import { HtmlContentModel } from '../models/content-models';
import { LocaleService } from '../locale/locale.service';

@Component({
  templateUrl: './sharedHelpTextModal.component.html'
})

export class SharedHelpTextModalComponent implements OnInit {
  content$: Observable<string>;

  constructor(
    public dialogRef: MatDialogRef<SharedHelpTextModalComponent>,
    private contentService: ContentService,
    @Inject(MAT_DIALOG_DATA) public data: SharedHelpModel) {
  }

  ngOnInit() {
    this.content$ = this.contentService.getContent(this.data.name || this.data.title, this.data.locale).pipe(
      map(content => (<HtmlContentModel>content).html),
      catchError(_ => of('No Help Content Available')),
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
