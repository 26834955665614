import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { select, Store } from '@ngrx/store';
import { selectLoggedInUser } from '../../_store/app.selectors';
import { tap, map } from 'rxjs/operators';
import { AppState } from '../../_store/app-state.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
		constructor(
			private store: Store<AppState>,
			private authService: AuthenticationService,
			private router: Router) { }

		canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
			return this.store.pipe(
				select(selectLoggedInUser),
				map(user => {
					if (user === undefined || !user) {
						if (!!environment.loginRedirectUrl) {
							window.location.href = environment.loginRedirectUrl;
						} else {
							this.router.navigateByUrl('/login');
            }
						return false;
					}

					if (!route.data.allowAnonymous) {
							const role = user.role;
							if (!route.data.roles.find(r => r == role)) {
									console.log('Not authorized to access [' + route.url + ']');
									if (route.data.authFailRedirectTo) {
											this.router.navigateByUrl(route.data.authFailRedirectTo);
									} else {
											this.router.navigateByUrl('/');
									}
							}
					}
					return true;
				})
			);
		}

	canActivateChild(route: ActivatedRouteSnapshot) {
		var authInfo = this.authService.getUser();
		if (authInfo.isAuthenticated) {
			const canAccessRoute = !route.data.roles || route.data.roles.find(r => r === authInfo.role);
			// If we've defined permissions for this route, check them
			if (!canAccessRoute) {
					console.log('Not authorized to access [' + route.url + ']');
					if (route.data.authFailRedirectTo) {
						this.router.navigateByUrl(route.data.authFailRedirectTo);
					} else {
						this.router.navigateByUrl('/');
					}
			}

			return true;
		} else {
			this.router.navigateByUrl('/login');
			return false;
		}
	}
}
